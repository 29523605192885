<template>
    <div>
        <!-- Modals -->
        <confirmation-modal
            v-model="modals.confirmation_delete_all_rooms_modal.show"
            :confirmation_message="
                modals.confirmation_delete_all_rooms_modal.confirmation_message
            "
            :confirmation_sub_message="
                modals.confirmation_delete_all_rooms_modal
                    .confirmation_sub_message
            "
            @confirmed="handle_multi_let_to_outright_change"
        >
        </confirmation-modal>
        <b-card>
            <!-- Card body -->
            <b-card-body class="p-0">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                >
                    <b-form
                        @submit.prevent="handleSubmit(on_form_submit)"
                        ref="future_rental_plan_form"
                    >
                        <h6 class="heading-small text-muted mb-4">
                            Future Rental Plan
                        </h6>
                        <!-- ## Add student accomodation -->

                        <div class="pl-lg-4">
                            <!-- Rates and Taxes -->
                            <b-row>
                                <b-col lg="6">
                                    <b-form-checkbox
                                        class="mb-3"
                                        v-model="form.allows_subletting"
                                        @change="filter_rental_approach_options"
                                    >
                                        Allows subletting?
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col lg="6">
                                    <b-form-checkbox
                                        class="mb-3"
                                        v-model="form.max_occupants.specified"
                                    >
                                        Max occupants specified?
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <Transition>
                                <b-row v-if="form.max_occupants.specified">
                                    <b-col lg="4">
                                        <base-input
                                            label="Maximum occupants allowed*"
                                            type="number"
                                            class="mb-3"
                                            placeholder="How many occupants are allowed? "
                                            name="Max Occupants"
                                            v-model="form.max_occupants.number"
                                            :rules="{
                                                required:
                                                    form.max_occupants
                                                        .specified,
                                            }"
                                            @keyup="debounced_on_form_submit"
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                            </Transition>
                            <b-row>
                                <b-col lg="4">
                                    <base-input
                                        label="Future Rental Strategy"
                                        :error="
                                            form.rental_approach.error_message
                                        "
                                        name="Future Rental Strategy"
                                        :rules="{ required: true }"
                                    >
                                        <el-select
                                            v-model="
                                                form.rental_approach.selection
                                            "
                                            filterable
                                            placeholder="Select"
                                            @change="
                                                handle_change_rental_strategy()
                                            "
                                        >
                                            <el-option
                                                v-for="option in form
                                                    .rental_approach.options"
                                                :key="option.label"
                                                :label="option.label"
                                                :value="option.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <div
                                            class="invalid-feedback"
                                            style="display: block"
                                            v-if="
                                                form.rental_approach
                                                    .error_message
                                            "
                                        >
                                            {{
                                                form.rental_approach
                                                    .error_message
                                            }}
                                        </div>
                                    </base-input>
                                </b-col>
                                <b-col
                                    lg="4"
                                    v-if="form.months_of_year_occupied.show"
                                >
                                    <base-input
                                        label="Months of the year occupied"
                                        type="number"
                                        class="mb-3"
                                        placeholder="How many months is it rented out?"
                                        name="Months occupied"
                                        v-model="
                                            form.months_of_year_occupied.value
                                        "
                                        @keyup="debounced_on_form_submit"
                                        :rules="{
                                            max_value: 12,
                                        }"
                                    >
                                    </base-input>
                                </b-col>
                            </b-row>

                            <!-- <base-button
                                type="primary"
                                :pill="true"
                                native-type="submit"
                                class="my-4"
                                :loading="save_button.is_loading"
                                :success="save_button.show_success"
                                :disabled="save_button.is_disabled"
                                v-if="!main_subunit.id"
                                >Save
                            </base-button>
                            <base-button
                                type="primary"
                                :pill="true"
                                native-type="submit"
                                class="my-4"
                                :loading="save_button.is_loading"
                                :success="save_button.show_success"
                                :disabled="save_button.is_disabled"
                                v-else
                                >Update
                            </base-button> -->
                        </div>
                    </b-form>
                </validation-observer>
                <!-- Rooms -->
                <Transition>
                    <listing-rental-rooms-component
                        v-if="rooms.show"
                        :listing_id="listing_id"
                        :main_subunit_id="main_subunit.id"
                    ></listing-rental-rooms-component>
                </Transition>
            </b-card-body>
        </b-card>
        <!-- Financial Information -->
        <Transition>
            <b-card class="p-0" v-if="outright_rental.show">
                <b-card-body class="p-0">
                    <h6 class="heading-small text-muted mt-3">
                        Rental Information
                        <Transition>
                            <i
                                v-if="$apollo.loading && !create_mode"
                                class="fas fa-spinner fa-spin"
                            ></i>
                        </Transition>
                    </h6>
                    <validation-observer
                        v-slot="{ handleSubmit }"
                        ref="formValidator"
                    >
                        <b-form
                            ref="rentalInformation"
                            @submit.prevent="
                                handleSubmit(rental_information_form_on_submit)
                            "
                        >
                            <div class="pl-lg-4">
                                <b-row>
                                    <b-col sm="6" lg="4">
                                        <base-input
                                            label="Projected Rent*"
                                            type="number"
                                            class="mb-3"
                                            placeholder="Projected Rent"
                                            name="Projected Rent"
                                            :rules="{ required: true }"
                                            v-model="
                                                form.rental_information
                                                    .expected_rent
                                            "
                                            :disabled="
                                                $apollo.loading && !create_mode
                                            "
                                            @keyup="
                                                debounced_rental_information_form_on_submit
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row class="">
                                    <b-col lg="6">
                                        <!-- <b-form-checkbox
                                            class="mb-3"
                                            v-model="
                                                form.rental_information
                                                    .current_lease
                                            "
                                            :disabled="
                                                $apollo.loading && !create_mode
                                            "
                                        >
                                            Current Lease?
                                        </b-form-checkbox> -->
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </Transition>
        <Transition>
            <b-card class="p-0" v-if="form.rental_information.current_lease">
                <b-card-body class="p-0">
                    <h6 class="heading-small text-muted mt-3">
                        Current Rental Information
                        <Transition>
                            <i
                                v-if="$apollo.loading && !create_mode"
                                class="fas fa-spinner fa-spin"
                            ></i>
                        </Transition>
                    </h6>
                    <validation-observer
                        v-slot="{ handleSubmit }"
                        ref="formValidator"
                    >
                        <b-form @submit.prevent="handleSubmit(onSubmit)">
                            <div class="pl-lg-4">
                                <b-row>
                                    <b-col sm="6" lg="4">
                                        <base-input
                                            label="Current Rent (R)*"
                                            type="number"
                                            class="mb-3"
                                            placeholder="Current Rent"
                                            name="Current Rent"
                                            :rules="{ required: true }"
                                            v-model="
                                                form.current_rental_information
                                                    .current_rent
                                            "
                                            :disabled="
                                                $apollo.loading && !create_mode
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="6" lg="4">
                                        <base-input
                                            label="Deposit Amount (R)"
                                            type="number"
                                            class="mb-3"
                                            placeholder="Deposit Amount"
                                            name="Deposit Amount"
                                            :rules="{ required: false }"
                                            v-model="
                                                form.current_rental_information
                                                    .deposit_amount
                                            "
                                            :disabled="
                                                $apollo.loading && !create_mode
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="6" lg="4">
                                        <base-input
                                            label="Annual Escalation (%)*"
                                            type="number"
                                            class="mb-3"
                                            placeholder="Annual Escalation"
                                            name="Annual Escalation"
                                            :rules="{ required: true }"
                                            v-model="
                                                form.current_rental_information
                                                    .annual_escalation_percentage
                                            "
                                            :disabled="
                                                $apollo.loading && !create_mode
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row class="">
                                    <b-col>
                                        <b-row>
                                            <b-col>
                                                <b-form-checkbox
                                                    class="mb-3"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .includes_electricity
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                    Includes Electricity
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-checkbox
                                                    class="mb-3"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .includes_water
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                    Includes Water
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-checkbox
                                                    class="mb-3"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .includes_wifi
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                    Includes WiFi
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </Transition>
    </div>
</template>
<script>
// Modules
import { debounce } from "debounce";

// Components
import ListingRentalRoomsComponent from "@/views/Components/Listing/ListingRentalRoomsComponent.vue";
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";

// Queries
import { GET_ALL_PROPERTY_RENTAL_APPROACH } from "@/graphql/queries";
import { GET_ALL_PROPERTY_SUBUNIT_TYPES } from "@/graphql/queries";
import { GET_PROPERTY_SUBUNIT_MAIN } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_SUBUNITS_LIGHT } from "@/graphql/queries";

// Mutations
import { CREATE_PROPERTY_SUBUNIT } from "@/graphql/mutations";
import { UPDATE_PROPERTY_SUBUNIT } from "@/graphql/mutations";
import { DELETE_PROPERTY_SUBUNITS } from "@/graphql/mutations";

import { Select, Option } from "element-ui";

export default {
    name: "FutureRentalPlanComponent",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        ListingRentalRoomsComponent,
        ConfirmationModal,
    },
    props: {
        listing_id: {
            type: String,
            description: "ID",
            default: "",
        },
    },
    apollo: {
        get_property_listing_main_subunit: {
            query: GET_PROPERTY_SUBUNIT_MAIN,
            result(data) {
                this.handle_get_property_listing_main_subunit(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_listing_main_subunit = data;
            },
            variables() {
                return {
                    subunit_type_id: this.main_subunit_type_id,
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
        get_property_listing_subunits: {
            query: GET_PROPERTY_LISTING_SUBUNITS_LIGHT,
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_listing_subunits = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
        get_rental_approaches: {
            query: GET_ALL_PROPERTY_RENTAL_APPROACH,
            result(data) {
                this.handle_get_rental_approaches(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_rental_approaches = data;
            },
        },
        get_property_subunit_types: {
            query: GET_ALL_PROPERTY_SUBUNIT_TYPES,
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            result() {
                this.main_subunit_type_id = this.get_main_subunit_type_id();
                this.enable_get_property_listing_main_subunit();
            },
            update(data) {
                this.apollo_data.get_property_subunit_types = data;
            },
        },
    },
    data() {
        return {
            modals: {
                confirmation_delete_all_rooms_modal: {
                    show: false,
                    confirmation_message: "Warning",
                    confirmation_sub_message:
                        "Changing from 'Multi-Let' to 'Single Family' rental strategy, deletes all subunits or rooms. Are you sure?",
                },
            },
            apollo_data: {
                get_rental_approaches: "",
                get_property_subunit_types: "",
                get_property_listing_main_subunit: "",
                get_property_listing_subunits: "",
            },
            form: {
                months_of_year_occupied: {
                    value: 12,
                    show: false,
                },
                allows_subletting: false,
                max_occupants: {
                    specified: true,
                    number: "",
                },
                rental_approach: {
                    selection: "",
                    options: [
                        {
                            label: null,
                            value: null,
                        },
                    ],
                    all_options: [],
                    error_message: "",
                    previous_selection: "",
                },
                rental_information: {
                    expected_rent: "",
                    current_lease: false,
                },
                current_rental_information: {
                    current_rent: "",
                    deposit_amount: 0,
                    annual_escalation_percentage: "",
                    includes_electricity: false,
                    includes_water: false,
                    includes_wifi: false,
                },
            },
            save_button: {
                is_loading: false,
                show_success: false,
                is_disabled: false,
            },
            rooms: {
                show: false,
            },
            outright_rental: {
                show: false,
            },
            main_subunit_type_id: "",
            main_subunit: {},
        };
    },
    methods: {
        // Apollo handlers
        handle_get_rental_approaches(data) {
            let multi_let_excluded_options = [
                "MULTI-LET",
                "STUDENT_ACCOMMODATION",
            ];

            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data)
            );
            let options = [];
            let all_options = [];
            if (flattened.data__allPropertyRentalApproach.length < 1) {
                console.log("No rental strategies received.");
                return;
            }
            flattened.data__allPropertyRentalApproach.forEach((arrayItem) => {
                all_options.push({
                    label: arrayItem.friendlyName,
                    value: arrayItem.id,
                    name: arrayItem.name,
                    friendly_name: arrayItem.friendlyName,
                    id: arrayItem.id,
                });

                if (
                    !this.form.allows_subletting &&
                    !multi_let_excluded_options.includes(arrayItem.name)
                ) {
                    options.push({
                        label: arrayItem.friendlyName,
                        value: arrayItem.id,
                        name: arrayItem.name,
                        friendly_name: arrayItem.friendlyName,
                        id: arrayItem.id,
                    });
                } else {
                    options.push({
                        label: arrayItem.friendlyName,
                        value: arrayItem.id,
                        name: arrayItem.name,
                        friendly_name: arrayItem.friendlyName,
                        id: arrayItem.id,
                    });
                }
            });
            this.form.rental_approach.options = options;
            this.form.rental_approach.all_options = all_options;
            this.handle_change_rental_strategy(true);
        },

        handle_get_property_listing_main_subunit(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            if (flattened.propertyListing__subunits.length > 0) {
                this.main_subunit = flattened.propertyListing__subunits[0];
            } else {
                this.main_subunit.id = "";
            }
            this.populate_form();
        },
        // Utils
        get_current_selection_name() {
            for (let i = 0; i < this.form.rental_approach.options.length; i++) {
                if (
                    this.form.rental_approach.selection ==
                    this.form.rental_approach.options[i].value
                ) {
                    return this.form.rental_approach.options[i].name;
                }
            }
            return "";
        },
        get_previous_approach_name() {
            for (let i = 0; i < this.form.rental_approach.options.length; i++) {
                if (
                    this.form.rental_approach.previous_selection ==
                    this.form.rental_approach.options[i].value
                ) {
                    return this.form.rental_approach.options[i].name;
                }
            }
            return "";
        },
        get_main_subunit_type_id() {
            let flattened = graph_utils.flatten_objects_recursive(
                this.apollo_data.get_property_subunit_types
            );
            for (
                let i = 0;
                i < flattened.allPropertySubunitType__edges.length;
                i++
            ) {
                if (
                    flattened.allPropertySubunitType__edges[i].node__name ==
                    "MAIN"
                ) {
                    return flattened.allPropertySubunitType__edges[i].node__id;
                }
            }
        },

        enable_get_property_listing_main_subunit() {
            if (!this.$apollo.queries.get_property_listing_main_subunit.skip) {
                this.$apollo.queries.get_property_listing_main_subunit.refetch();
            } else {
                this.$apollo.queries.get_property_listing_main_subunit.skip = false;
                this.$apollo.queries.get_property_listing_main_subunit.start();
            }
            if (!this.$apollo.queries.get_property_listing_subunits.skip) {
                this.$apollo.queries.get_property_listing_subunits.refetch();
            } else {
                this.$apollo.queries.get_property_listing_subunits.skip = false;
                this.$apollo.queries.get_property_listing_subunits.start();
            }
        },
        filter_rental_approach_options() {
            this.form.rental_approach.selection = "";
            let multi_let_excluded_options = [
                "MULTI_LET",
                "STUDENT_ACCOMMODATION",
            ];
            let new_options = [];
            if (!this.form.allows_subletting) {
                this.form.rental_approach.all_options.forEach((element) => {
                    if (!multi_let_excluded_options.includes(element.name)) {
                        new_options.push(element);
                    }
                });
                this.form.rental_approach.options = new_options;
            } else {
                this.form.rental_approach.options =
                    this.form.rental_approach.all_options;
            }
            this.debounced_on_form_submit();
        },

        // Form data presentation
        populate_form() {
            if (!this.main_subunit.id) {
                return;
            }

            // Populating the form.
            if ("futureRentalApproach__id" in this.main_subunit) {
                this.form.rental_approach.selection =
                    this.main_subunit.futureRentalApproach__id;
                this.form.rental_approach.previous_selection =
                    this.main_subunit.futureRentalApproach__id;
                this.handle_change_rental_strategy(true);
            }
            if ("allowsSubletting" in this.main_subunit) {
                this.form.allows_subletting =
                    this.main_subunit.allowsSubletting;
            }
            if ("expectedRentalIncome__amount" in this.main_subunit) {
                this.form.rental_information.expected_rent =
                    this.main_subunit.expectedRentalIncome__amount;
            }

            if ("maxOccupantCount" in this.main_subunit) {
                if (this.main_subunit.maxOccupantCount < 1) {
                    this.form.max_occupants.specified = false;
                } else {
                    this.form.max_occupants.specified = true;
                    this.form.max_occupants.number =
                        this.main_subunit.maxOccupantCount;
                }
            }
            if ("futureRentalApproachMonths" in this.main_subunit) {
                this.form.months_of_year_occupied.value =
                    this.main_subunit.futureRentalApproachMonths;
            }
        },

        // Event Handlers
        debounced_on_form_submit: debounce(function () {
            this.on_form_submit();
        }, 1000),

        on_form_submit() {
            this.save_button.is_loading = true;
            this.save_button.is_disabled = true;

            if (this.main_subunit.id) {
                this.update_main_subunit();
            } else {
                this.create_main_subunit();
            }
        },

        handle_multi_let_to_outright_change(confirmed) {
            if (confirmed) {
                this.rooms.show = false;
                this.form.months_of_year_occupied.show = false;
                this.form.months_of_year_occupied.value = 12;
                this.outright_rental.show = true;
                // delete all rooms for listing
                this.delete_all_rooms();
                setTimeout(() => {
                    this.on_form_submit();
                }, 200);
            } else {
                this.form.rental_approach.selection =
                    this.form.rental_approach.previous_selection;
            }
        },

        handle_change_rental_strategy(starting = false) {
            if (
                ["STUDENT_ACCOMMODATION", "MULTI_LET"].includes(
                    this.get_previous_approach_name()
                )
            ) {
                if (this.get_current_selection_name() === "OUTRIGHT") {
                    // Show confirmation modal
                    this.modals.confirmation_delete_all_rooms_modal.show = true;
                    return;
                }
            }
            if (
                ["STUDENT_ACCOMMODATION", "MULTI_LET"].includes(
                    this.get_current_selection_name()
                )
            ) {
                this.rooms.show = true;
            } else {
                this.rooms.show = false;
                this.form.months_of_year_occupied.show = false;
                this.form.months_of_year_occupied.value = 12;
            }
            if (this.get_current_selection_name() == "STUDENT_ACCOMMODATION") {
                this.form.months_of_year_occupied.show = true;
            } else {
                this.form.months_of_year_occupied.show = false;
                this.form.months_of_year_occupied.value = 12;
            }

            if (this.get_current_selection_name() === "OUTRIGHT") {
                this.outright_rental.show = true;
            } else {
                this.outright_rental.show = false;
            }
            this.form.rental_approach.previous_selection =
                this.form.rental_approach.selection;
            if (!starting) {
                this.debounced_on_form_submit();
            }
        },

        debounced_rental_information_form_on_submit: debounce(function () {
            this.rental_information_form_on_submit();
        }, 1000),

        rental_information_form_on_submit() {
            let variables = {
                subunit_id: this.main_subunit.id,
                expected_rental_income:
                    this.form.rental_information.expected_rent,
            };
            this.$apollo.mutate({
                mutation: UPDATE_PROPERTY_SUBUNIT,
                variables: variables,
            });
        },

        // Mutations
        create_main_subunit() {
            let variables = {
                listing_id: this.listing_id,
                subunit_type_id: this.main_subunit_type_id,
                allows_subletting: this.form.allows_subletting,
            };
            if (this.form.rental_approach.selection) {
                variables.future_rental_apporach =
                    this.form.rental_approach.selection;
            }
            if (
                !this.form.max_occupants.specified ||
                !this.form.max_occupants.number
            ) {
                variables.max_occupant_count = 0;
            } else {
                if (variables.max_occupant_count == "") {
                    variables.max_occupant_count = 0;
                } else {
                    variables.max_occupant_count = Number(
                        this.form.max_occupants.number
                    );
                }
            }

            this.$apollo
                .mutate({
                    mutation: CREATE_PROPERTY_SUBUNIT,
                    variables: variables,
                })
                .then((res) => {
                    if ("data" in res && "propertyCreateSubunit" in res.data) {
                        this.main_subunit =
                            res.data.propertyCreateSubunit.subunit;
                    }

                    this.save_button.is_loading = false;
                    this.save_button.show_success = true;
                    setTimeout(() => {
                        this.save_button.show_success = false;
                        this.save_button.is_disabled = false;
                    }, 1000);
                });
        },

        update_main_subunit() {
            let variables = {
                subunit_id: this.main_subunit.id,
                listing_id: this.listing_id,
                subunit_type_id: this.main_subunit_type_id,
                allows_subletting: this.form.allows_subletting,
                max_occupant_count: 0,
                months_of_year_occupied: 12,
            };
            if (this.form.rental_approach.selection) {
                variables.future_rental_apporach =
                    this.form.rental_approach.selection;
            }
            if (!this.form.max_occupants.specified) {
                variables.max_occupant_count = 0;
            } else {
                variables.max_occupant_count = Number(
                    this.form.max_occupants.number
                );
            }
            if (this.form.months_of_year_occupied.value != 12) {
                variables.months_of_year_occupied = Number(
                    this.form.months_of_year_occupied.value
                );
            }

            this.$apollo
                .mutate({
                    mutation: UPDATE_PROPERTY_SUBUNIT,
                    variables: variables,
                })
                .then((res) => {
                    if ("data" in res && "propertyUpdateSubunit" in res.data) {
                        this.main_subunit =
                            res.data.propertyUpdateSubunit.subunit;
                    }
                    this.save_button.is_loading = false;
                    this.save_button.show_success = true;
                    setTimeout(() => {
                        this.save_button.show_success = false;
                        this.save_button.is_disabled = false;
                    }, 1000);
                });
        },
        delete_all_rooms() {
            // Find all room ids
            let room_ids = [];
            if (
                this.apollo_data.get_property_listing_subunits &&
                "allPropertySubunit" in
                    this.apollo_data.get_property_listing_subunits
            ) {
                let flattened = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        this.apollo_data.get_property_listing_subunits
                            .allPropertySubunit
                    )
                );
                if (flattened.length > 0) {
                    flattened.forEach((element) => {
                        if (element.subunitType__name != "MAIN") {
                            room_ids.push(element.id);
                        }
                    });
                    this.delete_rooms(room_ids);
                }
            }
            // Delete all rooms
        },
        delete_room(id) {
            let variables = {
                subunit_id: id,
            };
            this.$apollo
                .mutate({
                    mutation: DELETE_PROPERTY_SUBUNITS,
                    variables: variables,
                })
                .then((res) => {
                    setTimeout(() => {
                        this.$emit("updated");
                    }, 500);
                });
        },

        delete_rooms(room_ids) {
            room_ids.forEach((element) => {
                this.delete_room(element);
            });
        },
    },
    mounted() {},
    watch: {
        "this.form.rental_approach.selection"() {
            this.handle_change_rental_strategy(true);
        },
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
</style>
