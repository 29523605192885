<template>
  <div>
    <b-col sm="12" md="12" lg="12">
      <h2>Buying Costs</h2>
    </b-col>
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <b-card>
          <!-- Card body -->

          <b-card-body class="p-0">
            <!-- Property Price -->
            <b-row class="mb-3">
              <b-col>
                <h5>
                  Financial Breakdown
                  <Transition>
                    <i
                      v-if="$apollo.loading"
                      class="fas fa-spinner fa-spin"
                    ></i>
                  </Transition>
                </h5>
              </b-col>
            </b-row>

            <!-- Buying costs -->
            <b-row
              v-for="transaction_cost in transaction_costs"
              :key="transaction_cost.id"
            >
              <b-col xs="6">
                <h2 class="text-default">
                  {{ transaction_cost.transactionCostType__friendlyName }}*
                </h2>
                <!-- Show grey text for Sourcing Fee -->
                <h6
                  v-if="
                    transaction_cost.transactionCostType__friendlyName ===
                    'Sourcing Fee'
                  "
                  class="text-muted"
                >
                  Sourcing fees are payable in full upon signing of the 'offer
                  to purchase' between the Seller and Buyer.
                </h6>
              </b-col>
              <b-col xs="6" class="text-right pr-md-5">
                <h2 class="text-default">
                  {{ transaction_cost.cost__currency__symbol }}&nbsp;{{
                    formatted_currency(transaction_cost.cost__amount)
                  }}
                </h2>
              </b-col>
            </b-row>
            <b-row v-if="modifications.length > 0">
              <b-col xs="6">
                <h2 class="text-default">Renovations / Refurbishment*</h2>
                <p
                  class="text-muted"
                  v-for="modification in modifications"
                  :key="modification.id"
                >
                  {{ modification.description }}
                </p>
              </b-col>
              <b-col sm="4" class="text-right pr-md-5">
                <h2 class="text-default">
                  R&nbsp;{{ formatted_currency(total_modifications()) }}
                </h2>
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="6">
                <hr class="my-1" />
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="6">
                <h2 class="text-orange">Total buying costs*</h2>
              </b-col>
              <b-col xs="6" class="text-right pr-md-5">
                <h2 class="text-orange">
                  R&nbsp;{{ formatted_currency(sum_all_buying_costs()) }}
                </h2>
              </b-col>
            </b-row>

            <!-- Negotiated Price -->

            <b-row class="mt-5">
              <b-col xs="6">
                <h2 class="text-default">Negotiated Price</h2>
              </b-col>
              <b-col xs="6" class="text-right pr-md-5">
                <h2 class="text-default">
                  {{ buying_price.symbol }}&nbsp;{{
                    formatted_currency(buying_price.amount)
                  }}
                </h2>
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="12">
                <hr class="mt-0" />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h1 class="text-primary">Total Investment*</h1>
              </b-col>
              <b-col xs="6" class="text-right pr-md-5">
                <h1 class="text-primary">
                  R&nbsp;{{ formatted_currency(sum_capital_investment()) }}
                </h1>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="pt-4">
                <h6 class="small">
                  *Amounts shown are estimates calculated on available
                  information.
                </h6>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "ListingCapitalInvestment",
  components: {},

  props: {
    transaction_costs: {
      type: Array,
      description: "List of transaction costs.",
      default() {
        return [
          // {
          //     id: "VHJhbnNhY3Rpb25Db3N0Tm9kZToyMQ==",
          //     transactionCostType__name: "SOURCING_FEE",
          //     transactionCostType__friendlyName: "Sourcing Fee",
          //     transactionCostType____typename:
          //         "TransactionCostTypeNode",
          //     cost__amount: 30000,
          //     cost__currency__symbol: "R",
          //     cost__currency____typename: "Currency",
          //     cost____typename: "Money",
          //     __typename: "TransactionCostNode",
          // },
        ];
      },
    },
    modifications: {
      type: Array,
      description: "List of modifications ",
      default() {
        return [
          // {
          //     id: "TW9kaWZpY2F0aW9uTm9kZTo3",
          //     description: "Test Renovation ",
          //     expectedCost__amount: 10000,
          //     expectedCost__currency__symbol: "R",
          //     expectedCost____typename: "Money",
          //     modificationType__name: "SUBDIVIDE",
          //     modificationType__friendlyName: "Subdivide",
          //     modificationType__id:
          //         "TW9kaWZpY2F0aW9uVHlwZU5vZGU6MQ==",
          // },
        ];
      },
    },
    buying_price: {
      type: Object,
      description: "Negotiated price of the property",
      default() {
        return {
          amount: 0,
          symbol: "",
        };
      },
    },
  },
  data() {
    return {
      apollo_data: {
        get_investment_financing: null,
      },

      renovation: {
        costs: {
          symbol: "R",
          amount: 3900,
        },
        description: "Subdivide one room and paint.",
        type: "",
      },
    };
  },
  methods: {
    formatted_currency(value) {
      return value.toLocaleString();
    },

    total_modifications() {
      let total_modifications = 0;
      if (this.modifications.length > 0) {
        this.modifications.forEach((modification) => {
          total_modifications += modification.expectedCost__amount;
        });
      }
      return total_modifications;
    },

    sum_all_buying_costs() {
      let total_transaction_costs = 0;

      this.transaction_costs.forEach((transaction_cost) => {
        total_transaction_costs += transaction_cost.cost__amount;
      });

      total_transaction_costs += this.total_modifications();

      return total_transaction_costs;
    },

    get_down_payments() {
      let total_down_payments = 0;

      this.transaction_costs.forEach((transaction_cost) => {
        if (
          ["CASH_PAYMENT", "BOND_DEPOSIT"].includes(
            transaction_cost.transactionCostType__name
          )
        ) {
          total_down_payments += transaction_cost.cost__amount;
        }
      });
      return total_down_payments;
    },

    sum_capital_investment() {
      return (
        this.sum_all_buying_costs() +
        this.buying_price.amount -
        this.get_down_payments()
      );
    },
  },
  watch: {},
};
</script>
<style></style>
