<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row>
          <b-col lg="12">
            <h1 class="display-1 text-orange pb-0 mb-0">
              Agreements Dashboard
            </h1>
            <p class="text-default bold mt-0 mb-5 font-weight-bold">
              Ensuring compliance, one agreement at a time.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-card class="shadow">
            <b-card-body class="p-0 card-border mx-3">
              <!-- Search -->
              <b-form
                class="navbar-search form-inline mr-sm-3 navbar-search-light"
                id="navbar-search-main"
                @submit.prevent="on_search_submit"
              >
                <b-form-group class="mb-4 mt-4 ml-4">
                  <b-input-group
                    class="input-group-alternative input-group-merge"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-search"></i
                      ></span>
                    </div>
                    <b-form-input
                      placeholder="Search"
                      type="text"
                      v-model="search.search_term"
                      @keyup="on_keyup_search"
                    >
                    </b-form-input>

                    <input type="submit" ref="search_form_submit" hidden />
                  </b-input-group>
                </b-form-group>

                <button
                  type="button"
                  class="close"
                  data-action="search-close"
                  data-target="#navbar-search-main"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </b-form>
              <div class="table-container">
                <b-table
                  v-show="!isLoading"
                  :items="filtered_user_list"
                  :fields="fields"
                  responsive="sm"
                  show-empty
                  fixed
                  borderless
                  striped
                  small
                  no-border-collapse
                  @sort-changed="onSorted"
                  class="align-items-center mb-0"
                  :style="{ overflow: 'show' }"
                >
                  <template #cell(name)="data">
                    <span class="text-grey text-left">
                      {{ data.item.owner.firstName }}
                      {{ data.item.owner.lastName }}
                    </span>
                  </template>
                  <template #cell(username)="data">
                    <span class="text-grey text-left">{{
                      data.item.owner.username
                    }}</span>
                  </template>
                  <template #cell(agreements)="data">
                    <span class="text-grey text-center">{{
                      data.item.executionSet.edgeCount
                    }}</span>
                  </template>
                  <template #cell(actions)="data">
                    <div
                      v-if="data.item.executionSet.edgeCount > 0"
                      class="text-center"
                    >
                      <router-link
                        :to="{
                          name: 'Agreements By User',
                          params: { account_id: data.item.id },
                        }"
                      >
                        <base-button type="default" size="sm" :pill="true"
                          >View</base-button
                        >
                      </router-link>
                    </div>
                  </template>
                </b-table>
                <div v-show="isLoading" class="text-center my-4 mt-5">
                  <i class="fas fa-spinner fa-spin" style="font-size: 2rem"></i>
                </div>
              </div>
              <div class="d-flex mt-5 mb-3 justify-content-center">
                <b-pagination
                  v-model="current_page"
                  :total-rows="total_items"
                  :per-page="items_per_page"
                  aria-controls="agreements-table"
                  limit="5"
                  hide-goto-end-buttons
                ></b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { debounce } from "debounce";
import { GET_ALL_USERS_WITH_EXECUTION_COUNT } from "@/graphql/queries";

export default {
  name: "AgreementsDashboard",
  data() {
    return {
      items_per_page: 50,
      current_page: 1,
      total_items: 0,
      filtered_user_list: [],
      user_list: [],
      isLoading: true,
      search: {
        search_term: "",
        is_loading: false,
      },
      sortBy: "", // Column to sort by
      previousSortBy: "",
      sortDesc: false, // Sort direction
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          thClass: "text-left text-muted",
          tdClass: "text-left",
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
          thClass: "text-left text-muted",
          tdClass: "text-left",
        },
        {
          key: "agreements",
          label: "Agreements",
          thClass: "text-center text-muted",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "Action",
          thClass: "text-center text-muted",
          tdClass: "text-right",
        },
      ],
    };
  },
  methods: {
    on_keyup_search() {
      this.search.is_loading = true;
      this.debounced_on_keyup_search();
    },
    debounced_on_keyup_search: debounce(function () {
      this.$refs.search_form_submit.click();
    }, 1000),
    on_search_submit() {
      this.current_page = 1;
      this.fetchPage();
    },
    getOffset() {
      return (this.current_page - 1) * this.items_per_page;
    },
    fetchPage() {
      this.isLoading = true;
      this.$apollo
        .query({
          query: GET_ALL_USERS_WITH_EXECUTION_COUNT,
          variables: {
            first: this.items_per_page,
            offset: this.getOffset(),
            search: this.search.search_term,
            orderBy: this.getOrderBy(),
          },
        })
        .then((result) => {
          if (result.data && result.data.allUserAccountAccount) {
            const newEdges = result.data.allUserAccountAccount.edges.map(
              (edge) => edge.node
            );
            this.user_list = newEdges;
            this.filtered_user_list = this.user_list;
            this.total_items = result.data.allUserAccountAccount.totalCount;
          }
        })
        .catch((error) => {
          console.error("Fetch More Error:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getOrderBy() {
      if (!this.sortBy) {
        return "owner__username";
      }
      let orderBy = this.sortBy;
      if (this.sortDesc) {
        orderBy = `-${orderBy}`;
      }
      return orderBy;
    },
    onSorted(ctx) {
      if (this.previousSortBy === ctx.sortBy) {
        this.sortDesc = !this.sortDesc;
      }
      this.previousSortBy = ctx.sortBy;
      this.sortBy =
        ctx.sortBy === "name" ? "owner__firstName" : "owner__username";

      this.fetchPage();
    },
  },

  created() {
    this.fetchPage();
  },
  watch: {
    current_page() {
      this.fetchPage();
    },
  },
};
</script>

<style scoped>
.table-container {
  height: 38vh; /* Adjust as needed */
  overflow-y: auto;
}

.table-container::-webkit-scrollbar {
  width: 10px;
  height: 16px;
  background-color: #f1f1f1b0;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #3a3a3a63;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-body {
  padding: 0px;
  margin: 0px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-grey {
  color: #6c757d; /* Adjust this color to match the desired grey */
}

.text-muted {
  color: #adb5bd !important; /* Faded grey color for the header */
}
</style>
