<template>
  <div>
    <!-- Modals -->
    <!-- CRUD Rooms Modal -->
    <crud-room-modal
      v-model="modal.show"
      :create_mode="modal.create_mode"
      :listing_id="listing_id"
      :main_subunit_id="main_subunit_id"
      :subunit_id="modal.active_id"
      @updated="handle_room_updated"
    >
    </crud-room-modal>

    <!-- Content -->
    <b-row class="justify-content-end pb-3">
      <!-- Heading -->
      <b-col>
        <h6 class="heading-small text-muted mt-3">
          Rooms
          <Transition>
            <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </Transition>
        </h6>
      </b-col>
      <!-- Add Button -->
      <b-col sm="1">
        <!-- v-b-modal.crud-room-modal -->
        <b-button
          @click="handle_room_create_click()"
          class="icon icon-shape rounded-circle shadow bg-default text-white"
        >
          <i class="fa fa-plus"></i>
        </b-button>
      </b-col>
    </b-row>
    <!-- Room Cards -->
    <b-row>
      <b-col>
        <div class="p-0">
          <!-- Room Card -->
          <b-card
            @click="handle_room_click(room.id)"
            v-for="room in rooms"
            :key="room.id"
          >
            <b-card-body class="p-0">
              <b-row>
                <b-col sm="12" md="4">
                  <h3 class="m-0">{{ room.name }}</h3>
                </b-col>
                <b-col class="d-sm-none d-md-block"></b-col>
                <b-col sm="12" md="3">
                  <h6 class="pt-2 float-md-right">Projected Rental Income</h6>
                </b-col>
                <b-col sm="12" md="2">
                  <h2 class="m-0 text-orange">
                    {{ room.expected_rental_income.symbol
                    }}{{
                      formatted_currency(room.expected_rental_income.amount)
                    }}
                  </h2>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <!-- No rooms placeholder -->
    <b-row v-if="rooms.length < 1 && !$apollo.loading">
      <b-col>
        <div class="p-0">
          <!-- Room Card -->
          <b-card @click="handle_room_create_click()">
            <b-card-body class="p-0">
              <b-row>
                <b-col xs="12" md="5">
                  <h3 class="m-0">No rooms found, please add...</h3>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Standard Components
import Badge from "@/components/Badge.vue";

// Custom Components
import CrudRoomModal from "@/views/Components/Listing/CRUDListingRentalRoomsModalComponent.vue";

//Queries
import { GET_ALL_PROPERTY_SUBUNIT_TYPE_ROOM } from "@/graphql/queries";
import { GET_ALL_PROPERTY_LISTING_ROOMS } from "@/graphql/queries";

export default {
  name: "ListingRentalRoomsComponent",
  components: {
    CrudRoomModal,
  },
  props: {
    listing_id: {
      type: String,
      description: "ID of the parent listing. ",
      default: "",
    },
    main_subunit_id: {
      type: String,
      description: "ID of the parent main-subunit. ",
      default: "",
    },
  },
  apollo: {
    get_all_property_listing_rooms: {
      query: GET_ALL_PROPERTY_LISTING_ROOMS,
      result(data) {
        this.handle_get_all_property_listing_rooms(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_property_listing_rooms = data;
      },
      skip: true,
      variables() {
        return {
          // listing_id: "TGlzdGluZ05vZGU6MzA=",
          listing_id: this.listing_id,
          // subunitType_id: "U3VidW5pdFR5cGVOb2RlOjQ=",
          subunitType_id: this.types.subunit_room_type_id,
        };
      },
    },
    get_property_subunit_type: {
      query: GET_ALL_PROPERTY_SUBUNIT_TYPE_ROOM,
      result(data) {
        this.handle_get_property_subunit_type(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_subunit_type =
          data.allPropertySubunitType;
      },
    },
  },
  data() {
    return {
      rooms: [
        // {
        //     id: "placeholder",
        //     name: "Example Name",
        //     expected_rental_income: {
        //         symbol: "R",
        //         amount: 50000,
        //     },
        // },
      ],
      modal: {
        show: false,
        active_id: "",
        create_mode: true,
      },
      types: {
        subunit_room_type_id: "",
      },
      apollo_data: {
        get_property_subunit_type: [],
        get_all_property_listing_rooms: [],
      },
    };
  },
  methods: {
    // Display functions
    formatted_currency(value) {
      return value.toLocaleString();
    },

    // Event handler functions
    handle_room_create_click() {
      this.modal.create_mode = true;
      this.modal.active_id = "";
      this.modal.show = true;
    },
    handle_room_click(id) {
      // Handles the clicking of a room
      this.modal.create_mode = false;
      this.modal.active_id = id;
      this.modal.show = true;
    },

    handle_room_updated() {
      this.enable_get_all_property_listing_rooms();
    },

    // Modal Functions
    handle_modal_hide() {
      this.modal.active_id = "";
    },

    // Apollo handlers
    handle_get_property_subunit_type(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data)
      );
      this.types.subunit_room_type_id =
        flattened.data__allPropertySubunitType[0].id;

      // Enabling the get_all_property_listing_rooms query
      this.enable_get_all_property_listing_rooms();
    },

    enable_get_all_property_listing_rooms() {
      if (this.types.subunit_room_type_id !== "" && this.listing_id !== "") {
        if (!this.$apollo.queries.get_all_property_listing_rooms.skip) {
          this.$apollo.queries.get_all_property_listing_rooms.refetch();
        } else {
          this.$apollo.queries.get_all_property_listing_rooms.skip = false;
          this.$apollo.queries.get_all_property_listing_rooms.start();
        }
      }
    },

    handle_get_all_property_listing_rooms(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data)
      );
      let rooms = [];
      if (flattened.data__allPropertySubunit.length > 0) {
        flattened.data__allPropertySubunit.forEach((element) => {
          let room = {
            id: element.id,
            name: element.description,
            expected_rental_income: {
              symbol: element.expectedRentalIncome__currency__symbol,
              amount: element.expectedRentalIncome__amount,
            },
          };
          rooms.push(room);
        });
      }
      this.rooms = rooms;
    },
  },
};
</script>
<style></style>
