import gql from "graphql-tag";

export const MUTATE_USER_PROFILE_CONTACT_DETAIL = gql`
  mutation ($phoneNumber: String) {
    mutateUserProfileContactDetail(
      action: "update"
      phoneNumber: $phoneNumber
    ) {
      ok
      value {
        phoneNumber
      }
    }
  }
`;
export const CREATE_PROPERTY_LISTING_ADDRESS = gql`
  mutation (
    $countryCode: String
    $gPlaceId: String
    $gAddress: String
    $gpsLong: Decimal
    $gpsLat: Decimal
    $streetNumber: String
    $streetName: String
    $suburbName: String
    $cityName: String
    $postalCode: String
    $province: String
  ) {
    propertyCreateListingAddress(
      input: {
        countryCode: $countryCode
        gPlaceId: $gPlaceId
        gAddress: $gAddress
        gpsLong: $gpsLong
        gpsLat: $gpsLat
        streetNumber: $streetNumber
        streetName: $streetName
        suburbName: $suburbName
        cityName: $cityName
        postalCode: $postalCode
        province: $province
      }
    ) {
      listingAddress {
        id
      }
    }
  }
`;
export const UPDATE_PROPERTY_LISTING_ADDRESS = gql`
  mutation (
    $address_id: ID!
    $countryCode: String
    $gPlaceId: String
    $gAddress: String
    $gpsLong: Decimal
    $gpsLat: Decimal
    $streetNumber: String
    $streetName: String
    $suburbName: String
    $cityName: String
    $postalCode: String
    $province: String
  ) {
    propertyUpdateListingAddress(
      id: $address_id
      input: {
        countryCode: $countryCode
        gPlaceId: $gPlaceId
        gAddress: $gAddress
        gpsLong: $gpsLong
        gpsLat: $gpsLat
        streetNumber: $streetNumber
        streetName: $streetName
        suburbName: $suburbName
        cityName: $cityName
        postalCode: $postalCode
        province: $province
      }
    ) {
      listingAddress {
        id
      }
    }
  }
`;
export const CREATE_PROPERTY_LISTING_BASIC = gql`
  mutation (
    $name: String!
    $listerUser: ID!
    $listerAccount: ID!
    $address: ID!
    $listingType: ID!
    $amenities: String!
    $description: String!
    $buyingPriceAmount: Decimal
    $marketPriceAmount: Decimal
  ) {
    propertyCreateListing(
      input: {
        name: $name
        listerUser: $listerUser
        listerAccount: $listerAccount
        address: $address
        listingType: $listingType
        description: $description
        amenities: $amenities
        buyingPrice: $buyingPriceAmount
        marketPrice: $marketPriceAmount
      }
    ) {
      listing {
        id
      }
    }
  }
`;
export const UPDATE_PROPERTY_LISTING_BASIC = gql`
  mutation (
    $listing_id: ID!
    $name: String!
    $listerUser: ID!
    $listerAccount: ID!
    $address: ID!
    $listingType: ID!
    $amenities: String!
    $description: String!
    $buyingPriceAmount: Decimal!
    $marketPriceAmount: Decimal
  ) {
    propertyUpdateListing(
      id: $listing_id
      input: {
        name: $name
        listerUser: $listerUser
        listerAccount: $listerAccount
        address: $address
        listingType: $listingType
        description: $description
        amenities: $amenities
        buyingPrice: $buyingPriceAmount
        marketPrice: $marketPriceAmount
      }
    ) {
      listing {
        id
      }
    }
  }
`;
export const UPDATE_PROPERTY_LISTING_STATUS = gql`
  mutation ($listing_id: ID!, $listing_status_id: ID!) {
    propertyUpdateListing(
      id: $listing_id
      input: { status: $listing_status_id }
    ) {
      listing {
        id
        status {
          id
          name
          friendlyName
        }
      }
    }
  }
`;

export const UPLOAD_ARTIFACT = gql`
  mutation ($file: Upload!) {
    uploadFile(file: $file) {
      success
    }
  }
`;
export const CREATE_PROPERTY_SUBUNIT = gql`
  mutation (
    $listing_id: ID!
    $subunit_type_id: ID!
    $allows_subletting: Boolean
    $max_occupant_count: Int
    $future_rental_apporach: ID
    $parent_subunit_id: ID
    $expected_rental_income: Decimal
    $is_partitioned: Boolean
    $to_be_created: Boolean
    $has_shared_kitchen: Boolean
    $has_shared_garden: Boolean
    $has_shared_bathroom: Boolean
    $description: String
    $size_m2: Decimal
  ) {
    propertyCreateSubunit(
      input: {
        listing: $listing_id
        subunitType: $subunit_type_id
        allowsSubletting: $allows_subletting
        maxOccupantCount: $max_occupant_count
        futureRentalApproach: $future_rental_apporach
        expectedRentalIncome: $expected_rental_income
        parentSubunit: $parent_subunit_id
        isPartitioned: $is_partitioned
        toBeCreated: $to_be_created
        hasSharedKitchen: $has_shared_kitchen
        hasSharedGarden: $has_shared_garden
        hasSharedBathroom: $has_shared_bathroom
        description: $description
        sizeM2: $size_m2
      }
    ) {
      subunit {
        id
      }
    }
  }
`;

export const UPDATE_PROPERTY_SUBUNIT = gql`
  mutation (
    $subunit_id: ID!
    $listing_id: ID
    $subunit_type_id: ID
    $allows_subletting: Boolean
    $max_occupant_count: Int
    $future_rental_apporach: ID
    $parent_subunit_id: ID
    $expected_rental_income: Decimal
    $is_partitioned: Boolean
    $to_be_created: Boolean
    $has_shared_kitchen: Boolean
    $has_shared_garden: Boolean
    $has_shared_bathroom: Boolean
    $description: String
    $size_m2: Decimal
    $bedroom_count: Decimal
    $bathroom_count: Decimal
    $months_of_year_occupied: Int
  ) {
    propertyUpdateSubunit(
      id: $subunit_id
      input: {
        listing: $listing_id
        subunitType: $subunit_type_id
        allowsSubletting: $allows_subletting
        maxOccupantCount: $max_occupant_count
        futureRentalApproach: $future_rental_apporach
        expectedRentalIncome: $expected_rental_income
        parentSubunit: $parent_subunit_id
        isPartitioned: $is_partitioned
        toBeCreated: $to_be_created
        hasSharedKitchen: $has_shared_kitchen
        hasSharedGarden: $has_shared_garden
        hasSharedBathroom: $has_shared_bathroom
        description: $description
        sizeM2: $size_m2
        bedroomCount: $bedroom_count
        bathroomCount: $bathroom_count
        futureRentalApproachMonths: $months_of_year_occupied
      }
    ) {
      subunit {
        id
        allowsSubletting
        maxOccupantCount
        futureRentalApproach {
          id
          name
          friendlyName
        }
        subunitType {
          id
          name
          friendlyName
        }
      }
    }
  }
`;
export const DELETE_PROPERTY_SUBUNITS = gql`
  mutation ($subunit_id: ID!) {
    propertyDeleteSubunit(id: $subunit_id) {
      deletedId
    }
  }
`;
export const DELETE_PROPERTY_ARTIFACT = gql`
  mutation ($artifact_id: ID!) {
    propertyDeleteArtifact(id: $artifact_id) {
      deletedId
    }
  }
`;
export const CREATE_NEW_PROPERTY_INTEREST = gql`
  mutation ($listing_id: ID!, $user_id: ID!) {
    propertyCreateInterest(input: { user: $user_id, listing: $listing_id }) {
      interest {
        id
      }
    }
  }
`;
export const CREATE_INVESTMENT_FINANCING = gql`
  mutation ($investment_id: ID!, $financing_type_id: ID!, $default: Boolean!) {
    investmentCreateFinancing(
      input: {
        investment: $investment_id
        financingType: $financing_type_id
        isDefault: $default
      }
    ) {
      financing {
        id
        financingType {
          id
          name
          friendlyName
        }
        isDefault
      }
    }
  }
`;
export const DELETE_INVESTMENT_FINANCING = gql`
  mutation ($financing_id: ID!) {
    investmentDeleteFinancing(id: $financing_id) {
      deletedId
    }
  }
`;
export const DELETE_INVESTMENT_RECURRING_EXPENSE = gql`
  mutation ($cost_id: ID!) {
    investmentDeleteRecurringExpense(id: $cost_id) {
      deletedId
    }
  }
`;
export const CREATE_INVESTMENT_RECURRING_EXPENSE = gql`
  mutation (
    $investment_id: ID!
    $currency_identifer: InvestmentRecurringExpenseCostCurrencyChoices!
    $description: String!
    $amount: Decimal!
    $financing_id: ID
  ) {
    investmentCreateRecurringExpense(
      input: {
        investment: $investment_id
        cost: $amount
        description: $description
        costCurrency: $currency_identifer
        financing: $financing_id
      }
    ) {
      recurringExpense {
        id
        description
        cost {
          currency {
            symbol
          }
          amount
        }
      }
    }
  }
`;
export const UPDATE_INVESTMENT_RECURRING_EXPENSE = gql`
  mutation (
    $investment_id: ID!
    $expense_id: ID!
    $currency_identifer: InvestmentRecurringExpenseCostCurrencyChoices!
    $description: String!
    $amount: Decimal!
    $financing_id: ID
  ) {
    investmentUpdateRecurringExpense(
      id: $expense_id
      input: {
        investment: $investment_id
        cost: $amount
        description: $description
        costCurrency: $currency_identifer
        financing: $financing_id
      }
    ) {
      recurringExpense {
        id
        description
        cost {
          currency {
            symbol
          }
          amount
        }
      }
    }
  }
`;
export const DELETE_INVESTMENT_TRANSACTION_COST = gql`
  mutation ($transaction_cost_id: ID!) {
    investmentDeleteTransactionCost(id: $transaction_cost_id) {
      deletedId
    }
  }
`;
export const UPDATE_INVESTMENT_TRANSACTION_COST = gql`
  mutation (
    $investment_id: ID!
    $transaction_cost_id: ID!
    $transaction_cost_type_id: ID
    $cost: Decimal
    $cost_currency: InvestmentTransactionCostCostCurrencyChoices
    $financing_id: ID
  ) {
    investmentUpdateTransactionCost(
      id: $transaction_cost_id
      input: {
        investment: $investment_id
        transactionCostType: $transaction_cost_type_id
        cost: $cost
        costCurrency: $cost_currency
        financing: $financing_id
      }
    ) {
      transactionCost {
        id
        transactionCostType {
          id
          name
          friendlyName
        }
        cost {
          amount
          currency {
            symbol
          }
        }
      }
    }
  }
`;
export const CREATE_INVESTMENT_TRANSACTION_COST = gql`
  mutation (
    $investment_id: ID!
    $transaction_cost_type_id: ID!
    $cost: Decimal!
    $cost_currency: InvestmentTransactionCostCostCurrencyChoices!
    $financing_id: ID
  ) {
    investmentCreateTransactionCost(
      input: {
        investment: $investment_id
        transactionCostType: $transaction_cost_type_id
        cost: $cost
        costCurrency: $cost_currency
        financing: $financing_id
      }
    ) {
      transactionCost {
        id
        transactionCostType {
          id
          name
          friendlyName
        }
        cost {
          amount
          currency {
            symbol
          }
        }
      }
    }
  }
`;

export const UPDATE_INVESTMENT_BOND = gql`
  mutation (
    $bond_id: ID!
    $projected_interest_rate: Decimal
    $monthly_repayment_amount: Decimal
    $monthly_repayment_currency: InvestmentBondMonthlyRepaymentsCurrencyChoices
    $deposit_amount: Decimal
    $deposit_currency: InvestmentBondDepositCurrencyChoices
    $term_months: Int
  ) {
    investmentUpdateBond(
      id: $bond_id
      input: {
        projectedInterestRatePercentage: $projected_interest_rate
        monthlyRepayments: $monthly_repayment_amount
        monthlyRepaymentsCurrency: $monthly_repayment_currency
        deposit: $deposit_amount
        depositCurrency: $deposit_currency
        termMonths: $term_months
      }
    ) {
      bond {
        id
      }
    }
  }
`;
export const DELETE_INVESTMENT_BOND = gql`
  mutation ($bond_id: ID!) {
    investmentDeleteBond(id: $bond_id) {
      deletedId
    }
  }
`;
export const CREATE_INVESTMENT_BOND = gql`
  mutation ($financing_id: ID!) {
    investmentCreateBond(input: { financing: $financing_id }) {
      bond {
        id
      }
    }
  }
`;
export const UPDATE_INVESTMENT_FINANCING_TYPE = gql`
  mutation ($financing_id: ID!, $financing_type_id: ID!) {
    investmentUpdateFinancing(
      id: $financing_id
      input: { financingType: $financing_type_id }
    ) {
      financing {
        id
      }
    }
  }
`;
export const UPDATE_PROPERTY_MODIFICATION = gql`
  mutation (
    $modification_id: ID!
    $description: String
    $modification_type_id: ID
    $expected_cost_amount: Decimal
    $expected_cost_currency: PropertyModificationExpectedCostCurrencyChoices
  ) {
    propertyUpdateModification(
      id: $modification_id
      input: {
        description: $description
        modificationType: $modification_type_id
        expectedCostCurrency: $expected_cost_currency
        expectedCost: $expected_cost_amount
      }
    ) {
      modification {
        id
        modified
      }
    }
  }
`;
export const CREATE_PROPERTY_MODIFICATION = gql`
  mutation (
    $subunit_id: ID!
    $listing_id: ID!
    $description: String!
    $modification_type_id: ID!
    $expected_cost_amount: Decimal!
    $expected_cost_currency: PropertyModificationExpectedCostCurrencyChoices
  ) {
    propertyCreateModification(
      input: {
        listing: $listing_id
        subunit: $subunit_id
        description: $description
        modificationType: $modification_type_id
        expectedCostCurrency: $expected_cost_currency
        expectedCost: $expected_cost_amount
      }
    ) {
      modification {
        id
      }
    }
  }
`;
export const DELETE_PROPERTY_MODIFICATION = gql`
  mutation ($modification_id: ID!) {
    propertyDeleteModification(id: $modification_id) {
      deletedId
    }
  }
`;
export const CREATE_APPROVAL_STATE_UPDATE = gql`
  mutation (
    $approval_id: ID!
    $new_state_id: ID!
    $comment: String
    $created_by_user_id: ID!
  ) {
    approvalCreateStateUpdate(
      input: {
        approval: $approval_id
        newState: $new_state_id
        comment: $comment
        createdBy: $created_by_user_id
      }
    ) {
      stateUpdate {
        id
      }
    }
  }
`;
export const CREATE_RATING_RATING_SUBMISSION = gql`
  mutation (
    $rating_id: ID!
    $user_id: ID!
    $rating_stars: Decimal!
    $comment: String
    $headline: String
  ) {
    ratingCreateRatingSubmission(
      input: {
        rating: $rating_id
        stars: $rating_stars
        headline: $headline
        comment: $comment
        user: $user_id
      }
    ) {
      ratingSubmission {
        id
      }
    }
  }
`;
export const UPDATE_RATING_RATING_SUBMISSION = gql`
  mutation (
    $rating_submission_id: ID!
    $rating_stars: Decimal
    $comment: String
    $headline: String
  ) {
    ratingUpdateRatingSubmission(
      id: $rating_submission_id
      input: { stars: $rating_stars, headline: $headline, comment: $comment }
    ) {
      ratingSubmission {
        id
      }
    }
  }
`;
export const CREATE_CONTRACT_EXECUTION = gql`
  mutation (
    $user_id: ID!
    $account_id: ID!
    $contract_id: ID!
    $contract_version_id: ID!
    $location: String
    $gps_long: Decimal
    $gps_lat: Decimal
  ) {
    contractCreateExecution(
      input: {
        user: $user_id
        account: $account_id
        contract: $contract_id
        contractVersion: $contract_version_id
        location: $location
        gpsLong: $gps_long
        gpsLat: $gps_lat
      }
    ) {
      execution {
        id
      }
    }
  }
`;
