var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header bg-gradient-success py-7 py-lg-8 pt-lg-9"},[_c('b-container',{staticClass:"container"},[_c('div',{staticClass:"header-body text-center mb-7"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"px-5",attrs:{"xl":"5","lg":"6","md":"8"}},[_c('h1',{staticClass:"text-white"},[_vm._v("Create an account")]),_c('p',{staticClass:"text-lead text-white"},[_vm._v(" Let's start your investRand journey right now ")])])],1)],1)]),_c('div',{staticClass:"separator separator-bottom separator-skew zindex-100"},[_c('svg',{attrs:{"x":"0","y":"0","viewBox":"0 0 2560 100","preserveAspectRatio":"none","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('polygon',{staticClass:"fill-default",attrs:{"points":"2560 0 2560 100 0 100"}})])])],1),_c('b-container',{staticClass:"mt--8 pb-5"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"lg":"6","md":"8"}},[_c('b-card',{staticClass:"bg-secondary border-0",attrs:{"no-body":""}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6 mt-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/brand/logos/investRand.svg"}})])]),_c('b-card-body',{staticClass:"px-lg-5 py-lg-5"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","prepend-icon":"ni ni-circle-08","placeholder":"Name","name":"Name","rules":{ required: true }},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","prepend-icon":"ni ni-circle-08","placeholder":"Surname","name":"Surname","rules":{ required: true }},model:{value:(_vm.model.surname),callback:function ($$v) {_vm.$set(_vm.model, "surname", $$v)},expression:"model.surname"}}),_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","prepend-icon":"ni ni-email-83","placeholder":"Email (will be used to validate account)","name":"Email","rules":{
                    required: true,
                    email: true,
                  },"no_spaces":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('base-input',{staticClass:"mb--1",attrs:{"alternative":"","name":"Cell Number","rules":{ required: true, digits: 10 },"prepend-icon":"fa fa-phone","placeholder":"Cell Number (will be used to validate account)","value":_vm.model.phone ? _vm.model.phone : '',"no_spaces":""},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('p',{staticClass:"text-muted small mb-4"},[_vm._v(" Your cell phone number will be used to validate your account. ")]),_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","prepend-icon":"ni ni-lock-circle-open","placeholder":"password","type":"password","name":"Password","rules":{ required: true, min: 8 }},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('base-input',{attrs:{"rules":{
                        required: {
                          allowFalse: false,
                        },
                      },"name":"Privacy & Terms and Conditions","Policy":""}},[_c('b-form-checkbox',{model:{value:(_vm.model.agree),callback:function ($$v) {_vm.$set(_vm.model, "agree", $$v)},expression:"model.agree"}},[_c('span',{staticClass:"text-muted"},[_vm._v("I agree with the "),_c('a',{attrs:{"href":"#/privacy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" & "),_c('a',{attrs:{"href":"#/tnc","target":"_blank"}},[_vm._v("Terms and Conditions")])])])],1)],1)],1),_c('div',{staticClass:"text-center"},[_vm._l((_vm.error_messages),function(error_message){return _c('div',{key:error_message},[_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(error_message)+" ")])])}),_c('base-button',{staticClass:"my-4",attrs:{"type":"primary","pill":true,"native-type":"submit","loading":_vm.is_loading,"disabled":_vm.is_loading}},[_vm._v("Create account")])],2)],1)]}}])}),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('base-button',{attrs:{"type":"default","pill":true,"disabled":_vm.is_loading},on:{"click":_vm.route_to_login}},[_vm._v("Have an Account? Log in")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }