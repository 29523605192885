<template>
  <div>
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <b-card>
          <!-- Card body -->

          <b-card-body class="p-0">
            <!-- Property Price -->
            <b-row>
              <b-col>
                <h6 class="heading-small text-muted mb-4">
                  Rental Income
                  <Transition>
                    <i
                      v-if="$apollo.loading"
                      class="fas fa-spinner fa-spin"
                    ></i>
                  </Transition>
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div>
                  <el-table
                    class="table-responsive"
                    header-row-class-name="thead-light"
                    :data="rooms"
                  >
                    <el-table-column label="Name" prop="name" min-width="200px">
                      <template v-slot="{ row }">
                        <h3>{{ row.name }}</h3>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Expected Income"
                      prop="ExpectedIncome"
                    >
                      <template v-slot="{ row }">
                        <h2 class="m-0 text-default">
                          {{ row.expected_rental_income.symbol }}
                          &nbsp;
                          {{
                            formatted_currency(
                              row.expected_rental_income.amount
                            )
                          }}
                        </h2>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-md-2">
              <!-- Title -->
              <b-col sm="12" md="6" class="ml-4 text-left align-middle my-auto">
                <h2>Total Monthly Rental Income</h2>
              </b-col>
              <!-- Cost amount -->
              <b-col
                class="mr-4 ml-4 ml-md-0 text-left text-md-right align-middle my-auto"
              >
                <h1 class="display-3 text-orange text-nowrap">
                  {{ total_expected_montly_income.symbol }}
                  {{ formatted_currency(total_expected_montly_income.amount) }}
                </h1>
              </b-col>
              <b-col sm="1"></b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Component imports
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";

//Queries
import { GET_ALL_PROPERTY_SUBUNIT_TYPE_ROOM } from "@/graphql/queries";
import { GET_ALL_PROPERTY_LISTING_ROOMS } from "@/graphql/queries";

export default {
  name: "ListingRentalIncome",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },

  props: {
    listing_id: {
      type: String,
      description: "The id of the listing in question",
      default: null,
    },
  },
  apollo: {
    get_all_property_listing_rooms: {
      query: GET_ALL_PROPERTY_LISTING_ROOMS,
      result(data) {
        this.handle_get_all_property_listing_rooms(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_property_listing_rooms = data;
      },
      skip: true,
      variables() {
        return {
          listing_id: this.listing_id,
          subunitType_id: this.types.subunit_room_type_id,
        };
      },
    },
    get_property_subunit_type: {
      query: GET_ALL_PROPERTY_SUBUNIT_TYPE_ROOM,
      result(data) {
        this.handle_get_property_subunit_type(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_subunit_type =
          data.allPropertySubunitType;
      },
    },
  },

  data() {
    return {
      apollo_data: {
        get_property_subunit_type: null,
        get_all_property_listing_rooms: null,
      },
      rooms: [
        // {
        //     name: "Testroom1",
        //     expected_rental_income: {
        //         symbol: "R",
        //         amount: 2000,
        //     },
        // },
      ],
      total_expected_montly_income: {
        symbol: "R",
        amount: 0,
      },
      types: {
        subunit_room_type_id: null,
      },
    };
  },
  methods: {
    // Apollo handlers
    handle_get_all_property_listing_rooms(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data)
      );
      let rooms = [];
      if (flattened.data__allPropertySubunit.length > 0) {
        flattened.data__allPropertySubunit.forEach((element) => {
          let room = {
            id: element.id,
            name: element.description,
            expected_rental_income: {
              symbol: element.expectedRentalIncome__currency__symbol,
              amount: element.expectedRentalIncome__amount,
            },
          };
          rooms.push(room);
        });
      }
      this.rooms = rooms;
    },

    handle_get_property_subunit_type(data) {
      if (
        !data ||
        !data.data ||
        !data.data.data__allPropertySubunitType ||
        !data.data.data__allPropertySubunitType[0]
      ) {
        return;
      }

      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data)
      );
      this.types.subunit_room_type_id =
        flattened.data__allPropertySubunitType[0].id;

      // Enabling the get_all_property_listing_rooms query
      this.enable_get_all_property_listing_rooms();
    },

    // Apollo managers
    enable_get_all_property_listing_rooms() {
      if (this.types.subunit_room_type_id !== "" && this.listing_id != "") {
        if (!this.$apollo.queries.get_all_property_listing_rooms.skip) {
          this.$apollo.queries.get_all_property_listing_rooms.refetch();
        } else {
          this.$apollo.queries.get_all_property_listing_rooms.skip = false;
          this.$apollo.queries.get_all_property_listing_rooms.start();
        }
      }
    },

    // Utils
    calculate_total_expected_monthly_income() {
      this.total_expected_montly_income.amount = 0;
      this.rooms.forEach((room) => {
        this.total_expected_montly_income.amount +=
          room.expected_rental_income.amount;
      });
    },

    formatted_currency(value) {
      return value.toLocaleString();
    },
  },
  watch: {
    "rooms.length"() {
      this.calculate_total_expected_monthly_income();
    },
  },
};
</script>
<style></style>
