<template>
  <div>
    <contract-modal v-model="showModal" :contract_id="contract_id">
    </contract-modal>
    <b-card>
      <!-- Card body -->
      <b-card-body class="p-0">
        <b-row>
          <!-- Title and Created Date/Time -->
          <b-col sm="8">
            <b-row>
              <b-col>
                <h1 class="font-weight-bold mb-0">
                  {{ contract_name }}
                </h1>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <small class="text-muted">
                  Created on {{ formattedDate }} at {{ formattedTime }}
                </small>
              </b-col>
            </b-row>
          </b-col>
          <!-- View Button -->
          <b-col
            sm="4"
            class="text-right d-flex align-items-center justify-content-end"
          >
            <base-button
              type="default"
              size="m"
              :pill="true"
              @click="showModal = true"
            >
              View
            </base-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ContractModal from "./ContractModal.vue";

export default {
  name: "AgreementItem",
  components: {
    ContractModal,
  },
  props: {
    execution_id: {
      type: String,
      description: "Id of the specific execution in the contract.",
      required: true,
    },
    contract_id: {
      type: String,
      description: "Id referencing the contract the execution belongs to.",
      required: true,
    },
    contract_name: {
      type: String,
      description: "Name of the contract.",
      required: true,
    },
    created_date_time: {
      type: String,
      description:
        "Date the execution was created i.e. date contract was agreed to.",
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      formattedDate: "",
      formattedTime: "",
    };
  },
  watch: {
    created_date_time: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const date = new Date(newVal);
          this.formattedDate = date.toLocaleDateString();
          this.formattedTime = date.toLocaleTimeString();
        } else {
          this.formattedDate = "";
          this.formattedTime = "";
        }
      },
    },
  },
};
</script>
