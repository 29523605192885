<template>
    <b-card>
        <b-card-body class="p-0">
            <div v-if="$apollo.loading">
                <i class="fas fa-spinner fa-spin"></i>
            </div>

            <div v-else>
                <!-- Name -->
                <b-row class="pb-0">
                    <b-col>
                        <h1 class="display-3 mb-0 font-weight-bold">
                            {{ service_provider.name }}
                        </h1>
                    </b-col>
                </b-row>
                <!-- Location -->
                <b-row class="pb-2" v-if="service_provider.location">
                    <b-col>
                        <small class="text-muted">
                            <i class="ni ni-pin-3"></i>
                        </small>
                        <small class="text-muted">
                            {{ service_provider.location }}
                        </small>
                    </b-col>
                </b-row>
                <!-- Rating -->
                <b-row>
                    <b-col>
                        <rating-view-widget-service-provider
                            :rating="service_provider.rating_stars"
                            :rating_count="service_provider.rating_count"
                        ></rating-view-widget-service-provider>
                    </b-col>
                </b-row>
                <!-- Detail Section-->
                <b-row class="pt-5">
                    <!-- Profile picture and contact detail -->
                    <b-col sm="12" md="4">
                        <!-- Profile Picture -->
                        <b-row>
                            <b-col>
                                <b-img-lazy
                                    left
                                    :src="service_provider.profile_picture_url"
                                    rounded
                                    fluid-grow
                                ></b-img-lazy>
                            </b-col>
                        </b-row>
                        <!-- Contact -->
                        <b-row class="text-center">
                            <b-col>
                                <b-button-group>
                                    <!-- Website -->
                                    <a
                                        class="px-1"
                                        :href="service_provider.website"
                                        target="_blank"
                                        v-if="service_provider.website"
                                    >
                                        <span class="display-4 text-default"
                                            ><i class="fas fa-globe"></i
                                        ></span>
                                    </a>
                                    <!-- Email -->
                                    <a
                                        class="px-1"
                                        :href="
                                            'mailto:' +
                                            service_provider.email_address
                                        "
                                        v-if="service_provider.email_address"
                                    >
                                        <span class="display-4 text-default"
                                            ><i class="fa fa-envelope"></i
                                        ></span>
                                    </a>
                                    <!-- Phone -->
                                    <a
                                        class="px-1"
                                        :href="
                                            'tel:' +
                                            service_provider.cellphone_number
                                        "
                                        target="_blank"
                                        v-if="service_provider.cellphone_number"
                                    >
                                        <span class="display-4 text-default"
                                            ><i class="fa fa-phone"></i
                                        ></span>
                                    </a>
                                    <!-- Whatsapp -->
                                    <a
                                        class="px-1"
                                        v-if="service_provider.has_whatsapp"
                                        :href="
                                            'https://api.whatsapp.com/send?phone=' +
                                            service_provider.cellphone_number
                                        "
                                        target="_blank"
                                    >
                                        <span class="display-4 text-default"
                                            ><i class="fab fa-whatsapp"></i
                                        ></span>
                                    </a>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Description -->
                    <b-col sm="12" md="8">
                        <div v-html="service_provider.description"></div>
                    </b-col>
                </b-row>
                <!-- Services -->
                <b-row class="pt-5">
                    <b-col>
                        <h2 class="text-orange">Services</h2>
                        <b-row>
                            <b-col
                                sm="6"
                                v-for="service in service_provider.services"
                                :key="service.id"
                            >
                                <p class="mb-0">
                                    <span class="text-orange"
                                        ><i class="far fa-check-circle"></i
                                    ></span>
                                    {{ service.friendly_name }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-card-body>
    </b-card>
</template>
<script>
// Queries
import { GET_SERVICE_PROVIDER_SERVICE_PROVIDER } from "@/graphql/queries";

// Components
import RatingViewWidgetServiceProvider from "@/views/Components/Rating/RatingViewWidgetServiceProvider.vue";

export default {
    name: "ServiceProviderDetailComponent",
    emits: ["rating-id"],
    components: {
        RatingViewWidgetServiceProvider,
    },
    apollo: {
        service_provider: {
            query: GET_SERVICE_PROVIDER_SERVICE_PROVIDER,
            result(data) {
                this.handle_service_provider(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.service_provider = data;
            },
            variables() {
                return {
                    id: this.id,
                };
            },
            skip: true,
        },
    },

    props: {
        id: {
            type: String,
            description: "ID",
            default: "",
        },
    },
    data() {
        return {
            apollo_data: {
                service_provider: [],
            },
            service_provider: {
                name: "",
                location: null, 
                description: "",
                website: "",
                cellphone_number: "",
                email_address: "",
                has_whatsapp: false,
                profile_picture_url: "",
                services: [],
                rating_stars: null,
                rating_count: null,
            },
        };
    },
    methods: {
        handle_service_provider(data) {
            let service_provider = graph_utils.flatten_relay_response(
                data.data.serviceProvidersServiceProvider
            );
            this.service_provider = {
                name: service_provider.name,
                location: service_provider.location,
                description: service_provider.detailDescription,
                website: service_provider.contactDetail__website,
                cellphone_number: service_provider.contactDetail__cellNumber,
                email_address: service_provider.contactDetail__emailAddress,
                has_whatsapp: true,
                profile_picture_url: service_provider.photo__file,
                services: [],
            };
            service_provider.services.forEach((element) => {
                this.service_provider.services.push({
                    id: element.id,
                    name: element.name,
                    friendly_name: element.friendlyName,
                });
            });
            this.$emit("rating-id", service_provider.rating__id);

            this.service_provider.rating_stars = Number(
                service_provider.rating__stars
            );
            this.service_provider.rating_count =
                service_provider.rating__submissions__totalCount;
        },
        enable_query() {
            this.$apollo.queries.service_provider.setOptions({
                fetchPolicy: "network-only",
            });
            if (!this.$apollo.queries.service_provider.skip) {
                this.$apollo.queries.service_provider.refetch();
            } else {
                this.$apollo.queries.service_provider.skip = false;
                this.$apollo.queries.service_provider.start();
            }
        },
    },
    mounted() {
        if (this.id) {
            this.enable_query();
        }
    },

    watch: {
        id(new_id) {
            this.enable_query();
        },
    },
};
</script>
<style></style>
