<template>
  <div>
    <confirmation-modal
      v-model="modals.confirmation_delete.show"
      :confirmation_message="modals.confirmation_delete.confirmation_message"
      :confirmation_sub_message="
        modals.confirmation_delete.confirmation_sub_message
      "
      @confirmed="handle_confirmation_delete_modal"
    >
    </confirmation-modal>

    <cru-buying-cost
      :show="modals.cru.show"
      :buying_cost_id="modals.cru.buying_cost_id"
      :investment_id="investment_id"
      @buying_cost_changed="handle_buying_cost_changed"
      :financing_id="financing_id"
      :financing_name="financing_name"
    ></cru-buying-cost>

    <b-card>
      <b-card-body class="p-0">
        <b-row class="pb-2">
          <b-col>
            <h6 class="heading-small text-muted mb-4">
              Buying Costs

              <Transition>
                <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
              </Transition>
            </h6>
          </b-col>

          <b-col class="text-right pr-2">
            <i
              class="fa fa-plus pull-right icon icon-shape rounded-circle shadow bg-default text-white"
              @click="toggle_cru_show('create')"
            >
            </i>
          </b-col>
        </b-row>
        <b-row class="d-none d-md-block">
          <b-col>
            <Transition>
              <el-table
                class="table-responsive table-flush"
                header-row-class-name="thead-light"
                :data="buying_costs"
              >
                <el-table-column
                  label="Name"
                  min-width="150px"
                  prop="name"
                  sortable
                >
                  <!-- <template v-slot="{ row }">
                                        <b-media no-body class="align-items-center">
                                            <a
                                                href="#"
                                                class="avatar rounded-circle mr-3"
                                            >
                                                <b-img
                                                    alt="Image placeholder"
                                                    :src="row.img"
                                                ></b-img>
                                            </a>
                                            <b-media-body>
                                                <span
                                                    class="font-weight-600 name mb-0 text-sm"
                                                    >{{ row.title }}</span
                                                >
                                            </b-media-body>
                                        </b-media>
                                    </template> -->
                </el-table-column>
                <el-table-column
                  label="Cost"
                  prop="cost.formatted"
                  width="120px"
                  sortable
                >
                </el-table-column>
                <el-table-column width="160px" align="right">
                  <template #default="scope">
                    <base-button
                      type="default"
                      size="sm"
                      :pill="true"
                      @click="handle_update_button(scope.row.id)"
                    >
                      <i class="fas fa-wrench"></i>
                    </base-button>
                    <base-button
                      type="danger"
                      size="sm"
                      :pill="true"
                      @click="
                        handle_delete_button(scope.row.id, scope.row.name)
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </base-button>
                  </template>
                </el-table-column>
              </el-table>
            </Transition>
          </b-col>
        </b-row>
        <b-row class="d-block d-md-none">
          <b-col>
            <buying-costs-item
              v-for="buying_cost in buying_costs"
              :key="buying_cost.id"
              :buying_cost_id="buying_cost.id"
              :buying_cost_name="buying_cost.name"
              :buying_cost_amount="buying_cost.cost.amount"
              :buying_coste_currency="buying_cost.cost.currency"
              @edit="handle_update_button"
              @delete="handle_delete_button"
            >
            </buying-costs-item>
          </b-col>
        </b-row>
        <!-- <b-row class="pt-1">
                    <b-col class="text-center"
                        ><router-link
                            to="create"
                            class="pull-right icon icon-shape rounded-circle shadow bg-default text-white"
                        >
                            <i class="fa fa-plus"></i>
                        </router-link>
                    </b-col>
                </b-row> -->
        <!-- <hr /> -->
        <b-row class="pt-md-2">
          <!-- Title -->
          <b-col sm="12" md="6" class="ml-4 text-left align-middle my-auto">
            <h2>Total Buying Costs</h2>
          </b-col>
          <!-- Cost amount -->
          <b-col
            class="mr-4 ml-4 ml-md-0 text-left text-md-right align-middle my-auto"
          >
            <h1 class="display-3 text-orange text-nowrap">
              {{ total_costs.currency }}
              {{ formatted_currency(total_costs.amount) }}
            </h1>
          </b-col>
          <b-col sm="1"></b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
//Custom Components
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";

// Queries
import { GET_ALL_INVESTMENT_TRANSACTION_COSTS } from "@/graphql/queries";

// Mutations
import { DELETE_INVESTMENT_TRANSACTION_COST } from "@/graphql/mutations";

// Standard Components
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";

// Components
import CruBuyingCost from "@/views/Components/Listing/Finances/Forms/CruBuyingCost.vue";
import BuyingCostsItem from "@/views/Components/Listing/Finances/Forms/BuyingCostsItem.vue";

export default {
  name: "BuyingCosts",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ConfirmationModal,
    CruBuyingCost,
    BuyingCostsItem,
  },

  props: {
    investment_id: {
      type: String,
      description: "Investment ID",
      default: null,
    },
    financing_id: {
      type: String,
      description: "Financing ID",
      default: null,
    },
    financing_name: {
      type: String,
      description: "The name of the financing type.",
      default: null, // Can be null if not specified
    },
  },
  apollo: {
    get_all_investment_buying_costs: {
      query: GET_ALL_INVESTMENT_TRANSACTION_COSTS,
      result(data) {
        this.handle_get_all_investment_buying_costs(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_investment_buying_costs = data;
      },
      variables() {
        return {
          investment_id: this.investment_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      modals: {
        confirmation_delete: {
          show: false,
          confirmation_message: "",
          deleting_id: "",
        },
        cru: { show: false, buying_cost_id: "" },
      },
      apollo_data: {
        get_all_investment_buying_costs: null,
      },
      buying_costs: [
        // {
        //     id: "",
        //     name: "Test Name",
        //     cost: {
        //         amount: 100,
        //         currency: "R",
        //         formatted: "R 100",
        //     },
        // },
      ],
      total_costs: {
        amount: 0,
        currency: "R",
      },
    };
  },
  methods: {
    // Modal handlers

    handle_buying_cost_changed(id) {
      this.modals.cru.buying_cost_id = id;
      this.enable_get_all_investment_buying_costs();
    },
    handle_delete_button(id, name) {
      this.modals.confirmation_delete.confirmation_message = `You are about to delete: '${name}'`;
      this.modals.confirmation_delete.show = true;
      this.modals.confirmation_delete.deleting_id = id;
    },

    handle_update_button(id) {
      this.modals.cru.buying_cost_id = id;
      this.toggle_cru_show("edit");
    },

    handle_confirmation_delete_modal() {
      this.$apollo
        .mutate({
          mutation: DELETE_INVESTMENT_TRANSACTION_COST,
          variables: {
            transaction_cost_id: this.modals.confirmation_delete.deleting_id,
          },
        })
        .then((res) => {
          this.enable_get_all_investment_buying_costs();
        });
    },

    toggle_cru_show(mode) {
      if (mode === "create") {
        this.modals.cru.buying_cost_id = "";
      }
      this.modals.cru.show = false;
      setTimeout(() => {
        this.modals.cru.show = true;
      }, 100);
    },

    // Apolo Handlers

    handle_get_all_investment_buying_costs(data) {
      if (!data || !data.data || !data.data.allInvestmentTransactionCost) {
        return;
      }

      this.buying_costs = [];
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );

      // Filter costs based on financing_id or include if no financing type exists
      flattened.allInvestmentTransactionCost.forEach((element) => {
        const financingId = element.financing__id || null;

        // Include the cost if financing matches, or if no financing type exists
        if (
          this.financing_id === null ||
          financingId === this.financing_id ||
          !financingId
        ) {
          this.buying_costs.push({
            id: element.id,
            name: element.transactionCostType__friendlyName,
            cost: {
              amount: element.cost__amount,
              currency: element.cost__currency__symbol,
              formatted: `${
                element.cost__currency__symbol
              } ${this.formatted_currency(element.cost__amount)}`,
            },
          });
        }
      });

      // Update total costs after filtering
      this.get_total_costs();
    },
    // Apollo Managers
    enable_get_all_investment_buying_costs() {
      this.$apollo.queries.get_all_investment_buying_costs.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_all_investment_buying_costs.skip) {
        this.$apollo.queries.get_all_investment_buying_costs.refetch();
      } else {
        this.$apollo.queries.get_all_investment_buying_costs.skip = false;
        this.$apollo.queries.get_all_investment_buying_costs.start();
      }
      if (!this.$apollo.queries.get_all_investment_buying_costs.skip) {
        this.$apollo.queries.get_all_investment_buying_costs.refetch();
      } else {
        this.$apollo.queries.get_all_investment_buying_costs.skip = false;
        this.$apollo.queries.get_all_investment_buying_costs.start();
      }
    },

    formatted_currency(value) {
      return value.toLocaleString();
    },

    // Data maintainers
    get_total_costs() {
      this.total_costs.amount = 0;
      this.buying_costs.forEach((element) => {
        this.total_costs.amount += element.cost.amount;
      });
    },
    update_formatted_currency() {
      for (let i = 0; i < this.operating_costs.length; i++) {
        this.operating_costs[i].cost.formatted = `${
          this.operating_costs[i].cost.currency
        } ${this.formatted_currency(this.operating_costs[i].cost.amount)}`;
      }
    },
  },
  watch: {
    investment_id() {
      if (this.investment_id !== "") {
        this.enable_get_all_investment_buying_costs();
      }
    },
  },
  mounted() {
    // this.update_formatted_currency();
    if (this.investment_id !== "") {
      this.enable_get_all_investment_buying_costs();
    }
  },
  created() {
    global_event_emitter.$on("buying_cost_update", () => {
      setTimeout(() => {
        this.enable_get_all_investment_buying_costs();
      }, 500);
    });
  },
  beforeDestroy() {
    global_event_emitter.$off("buying_cost_update");
  },
};
</script>
<style></style>
