<template>
  <b-card>
    <!-- Card body -->
    <b-card-body class="p-0">
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(on_submit)">
          <input type="submit" ref="form_submit_button" hidden />
          <h6 class="heading-small text-muted mb-4">
            Estimated Refurbishment Costs
            <Transition>
              <i
                v-if="$apollo.loading || form_is_loading"
                class="fas fa-spinner fa-spin"
              ></i>
            </Transition>
          </h6>

          <div class="pl-lg-4">
            <!-- Rates and Taxes -->
            <b-row>
              <b-col lg="6">
                <b-form-checkbox
                  class="mb-3"
                  v-model="renovations.planned"
                  @change="input_debounced"
                >
                  Planned renovations or improvements?
                </b-form-checkbox>
              </b-col>
            </b-row>
            <Transition>
              <div v-if="renovations.planned">
                <b-row>
                  <b-col lg="6">
                    <base-input
                      label="Description*"
                      class="mb-3"
                      placeholder="Enter description of the renovation / improvement."
                      name="Description"
                      :rules="{
                        required: renovations.planned,
                        min: 15,
                      }"
                      type="textarea"
                      rows="6"
                      v-model="renovations.description"
                      @keyup="input_debounced"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6"> </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <base-input
                      label="Type"
                      name="Type"
                      :rules="{
                        required: renovations.planned,
                      }"
                    >
                      <el-select
                        v-model="renovations.type.selection"
                        filterable
                        placeholder="Select"
                        @change="
                          form_is_loading = true;
                          $refs.form_submit_button.click();
                        "
                      >
                        <el-option
                          v-for="option in renovations.type.options"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="4">
                    <base-input
                      label="Projected Cost (R)*"
                      type="number"
                      class="mb-3"
                      placeholder="Total cost"
                      name="Projected Cost"
                      v-model="renovations.projected_cost"
                      :rules="{
                        required: renovations.planned,
                      }"
                      @keyup="input_debounced"
                    >
                    </base-input>
                  </b-col>
                </b-row>
              </div>
            </Transition>
          </div>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>
<script>
// Modules
import { debounce } from "debounce";
import { Select, Option } from "element-ui";

// Queries
import { GET_ALL_PROPERTY_MODIFICATION_TYPE } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_SUBUNITS_LIGHT } from "@/graphql/queries";
import { GET_PROPERTY_MODIFICATIONS_SUBUNIT } from "@/graphql/queries";

// Mutations
import { CREATE_PROPERTY_MODIFICATION } from "@/graphql/mutations";
import { UPDATE_PROPERTY_MODIFICATION } from "@/graphql/mutations";
import { DELETE_PROPERTY_MODIFICATION } from "@/graphql/mutations";

export default {
  name: "ListingRenovationComponent",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  apollo: {
    get_property_modification_type: {
      query: GET_ALL_PROPERTY_MODIFICATION_TYPE,
      result(data) {
        this.handle_get_property_modification_type(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_modification_type = data;
      },
    },
    get_main_subunit_id: {
      query: GET_PROPERTY_LISTING_SUBUNITS_LIGHT,
      result(data) {
        this.handle_get_main_subunit_id(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_main_subunit_id = data;
      },
      variables() {
        return {
          listing_id: this.listing_id,
        };
      },
      skip: true,
    },
    get_main_subunit_modifications: {
      query: GET_PROPERTY_MODIFICATIONS_SUBUNIT,
      result(data) {
        this.handle_get_main_subunit_modifications(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_main_subunit_modifications = data;
      },
      variables() {
        return {
          subunit_id: this.main_subunit_id,
        };
      },
      skip: true,
    },
  },
  props: {
    listing_id: {
      type: String,
      description: "Listing ID",
      default: null,
    },
  },

  data() {
    return {
      form_is_loading: false,
      main_subunit_id: null,
      modification_id: null,
      renovations: {
        planned: false,
        type: {
          selection: "",
          options: [
            {
              label: "",
              value: "",
            },
          ],
        },
        description: "",
        projected_cost: "",
      },
      apollo_data: {
        get_property_modification_type: "",
        get_main_subunit_id: null,
        get_main_subunit_modifications: null,
      },
    };
  },
  methods: {
    // Form handlers

    on_submit() {
      if (this.modification_id) {
        if (!this.renovations.planned) {
          this.delete_modification();
        } else {
          this.update_modification();
        }
      } else {
        this.create_modification();
      }
    },
    input_debounced: debounce(function () {
      this.form_is_loading = true;
      this.$refs.form_submit_button.click();
      setTimeout(() => {
        this.form_is_loading = false;
      }, 2000);
    }, 1000),

    update_modification() {
      this.$apollo
        .mutate({
          mutation: UPDATE_PROPERTY_MODIFICATION,
          variables: {
            modification_id: this.modification_id,
            description: this.renovations.description,
            modification_type_id: this.renovations.type.selection,
            expected_cost_amount: this.renovations.projected_cost,
          },
        })
        .then((res) => {
          this.form_is_loading = false;
        })
        .catch((res) => {
          this.form_is_loading = false;
        });
    },
    delete_modification() {
      this.$apollo
        .mutate({
          mutation: DELETE_PROPERTY_MODIFICATION,
          variables: {
            modification_id: this.modification_id,
          },
        })
        .then((res) => {
          this.form_is_loading = false;
          this.modification_id = null;
        })
        .catch((res) => {
          this.form_is_loading = false;
        });
    },
    create_modification() {
      this.$apollo
        .mutate({
          mutation: CREATE_PROPERTY_MODIFICATION,
          variables: {
            subunit_id: this.main_subunit_id,
            listing_id: this.listing_id,
            description: this.renovations.description,
            modification_type_id: this.renovations.type.selection,
            expected_cost_amount: this.renovations.projected_cost,
          },
        })
        .then((res) => {
          this.form_is_loading = false;

          this.modification_id =
            res.data.propertyCreateModification.modification.id;
        })
        .catch((res) => {
          this.form_is_loading = false;
        });
    },

    // Apollo handlers
    handle_get_main_subunit_modifications(data) {
      if (!data || !data.data) {
        return;
      }
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );

      if (flattened.allPropertyModification.length < 1) {
        this.renovations.planned = false;
      } else {
        this.renovations.planned = true;
        this.renovations.projected_cost =
          flattened.allPropertyModification[0].expectedCost__amount;
        this.renovations.type.selection =
          flattened.allPropertyModification[0].modificationType__id;
        this.renovations.description =
          flattened.allPropertyModification[0].description;
        this.modification_id = flattened.allPropertyModification[0].id;
      }
    },
    handle_get_main_subunit_id(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      for (let i = 0; i < flattened.allPropertySubunit.length; i++) {
        if (flattened.allPropertySubunit[i].subunitType__name == "MAIN") {
          this.main_subunit_id = flattened.allPropertySubunit[i].id;
        }
      }
    },

    handle_get_property_modification_type(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data)
      );
      this.renovations.type.options = [];
      flattened.data__allPropertyModificationType.forEach((element) => {
        this.renovations.type.options.push({
          label: element.friendlyName,
          value: element.id,
        });
      });
    },
    enable_get_main_subunit_id() {
      if (!this.$apollo.queries.get_main_subunit_id.skip) {
        this.$apollo.queries.get_main_subunit_id.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_id.skip = false;
        this.$apollo.queries.get_main_subunit_id.start();
      }
      if (!this.$apollo.queries.get_main_subunit_id.skip) {
        this.$apollo.queries.get_main_subunit_id.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_id.skip = false;
        this.$apollo.queries.get_main_subunit_id.start();
      }
    },
    enable_get_main_subunit_modifications() {
      if (!this.$apollo.queries.get_main_subunit_modifications.skip) {
        this.$apollo.queries.get_main_subunit_modifications.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_modifications.skip = false;
        this.$apollo.queries.get_main_subunit_modifications.start();
      }
      if (!this.$apollo.queries.get_main_subunit_modifications.skip) {
        this.$apollo.queries.get_main_subunit_modifications.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_modifications.skip = false;
        this.$apollo.queries.get_main_subunit_modifications.start();
      }
    },
  },

  watch: {
    listing_id() {
      if (this.listing_id !== null) {
        this.enable_get_main_subunit_id();
      }
    },
    main_subunit_id() {
      if (this.main_subunit_id !== null) {
        this.enable_get_main_subunit_modifications();
      }
    },
  },
  mounted() {
    if (this.listing_id !== null) {
      this.enable_get_main_subunit_id();
    }
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.text-area {
  border-radius: 1rem;
}
</style>
