<template>
    <div>
        <!-- Modals -->
        <div>
            <b-modal
                v-model="show"
                size="md"
                id="confirmation-modal"
                title="Are you sure?"
                ok-title="Save"
                @hidden="handle_modal_hide()"
                @ok="handle_ok"
                @cancel="handle_cancel"
                class="p-0"
            >
                <h1>
                    {{ confirmation_message }}
                </h1>
                <p v-if="confirmation_sub_message">{{confirmation_sub_message}}</p>
                <template #modal-footer="{ ok, cancel }">
                    <b-button variant="danger" @click="ok()"> Yes </b-button>
                    <b-button variant="success" @click="cancel()"> No </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
export default {
    name: "ConfirmationModal",
    emits: ["confirmed"],
    components: {},
    props: {
        value: Boolean,
        confirmation_message: {
            type: String,
            description: "Confirmation Message",
            default: "",
        },
        confirmation_sub_message: {
            type: String,
            description: "Confirmation sub Message",
            default: "",
        },
    },

    data() {
        return {
            show: this.value,
        };
    },
    methods: {
        // Event handler functions
        handle_modal_hide() {
            this.show = false;
        },
        handle_ok(){
            this.$emit("confirmed", true)
        },
        handle_cancel(){
            this.$emit("confirmed", false)
        }, 
    },
    watch: {
        show(new_show) {
            this.$emit("input", new_show);
        },

        value(new_value) {
            this.show = new_value;
        },
    },
};
</script>
<style></style>
