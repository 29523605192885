<template>
    <div class="dashboard-content-window">
        <!-- Header -->
        <div class="header bg-gradient-success pt-6 py-lg-6 pt-lg-7">
            <b-container>
                <div class="header-body text-center">
                    <b-row class="justify-content-center">
                        <b-col xl="8" lg="8" md="12" class="px-5">
                            <h1 class="text-white display-1">
                                Service Providers
                            </h1>
                            <p class="text-lead text-white">
                                Let's build your team!
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </div>

        <b-container class="mt-3">
            <b-row class="mb-4">
                <b-col sm="11"></b-col>

                <!-- Search -->
                <b-col sm="3">
                    <b-form
                        class="navbar-search form-inline mr-sm-3 navbar-search-light"
                        id="navbar-search-main"
                        @submit.prevent="on_search_submit"
                    >
                        <b-form-group class="mb-0">
                            <b-input-group
                                class="input-group-alternative input-group-merge"
                            >
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        ><i class="fas fa-search"></i
                                    ></span>
                                </div>
                                <b-form-input
                                    placeholder="Search"
                                    type="text"
                                    v-model="search.search_term"
                                    @keyup="on_keyup_search"
                                >
                                </b-form-input>

                                <input
                                    type="submit"
                                    ref="search_form_submit"
                                    hidden
                                />
                            </b-input-group>
                        </b-form-group>

                        <button
                            type="button"
                            class="close"
                            data-action="search-close"
                            data-target="#navbar-search-main"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </b-form>
                </b-col>
                <b-col>
                    <Transition>
                        <i
                            v-if="search.is_loading"
                            class="fas fa-spinner fa-spin ml--3 mt-3 text-muted"
                        ></i>
                    </Transition>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <h1 class="text-center">
                        <i
                            v-if="$apollo.loading"
                            class="fas fa-spinner fa-spin"
                        ></i>
                    </h1>
                </b-col>
                <b-col sm="12" class="blur-linear">
                    <div
                        v-for="service_provider in filtered_service_providers"
                        :key="service_provider.id"
                    >
                        <service-provider-item-un-auth
                            :service_provider_id="service_provider.id"
                            :name="service_provider.name"
                            :tags="service_provider.tags"
                            :location="service_provider.location"
                            :picture_url="service_provider.picture_url"
                            :short_description="
                                service_provider.short_description
                            "
                            :service_provider_types="['Property Manager']"
                            :rating_count="service_provider.rating_count"
                            :rating_stars="service_provider.rating_stars"
                            type="wide"
                        ></service-provider-item-un-auth>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt--5">
                <b-col class="text-center"
                v-if="!$apollo.loading"
                >
                    <h3>Please register to see more service providers.</h3>
                </b-col>
            </b-row>
            <b-row v-if="!$apollo.loading">
                <b-col class="text-center">
                    <base-button
                        pill
                        type="warning"
                        @click="$router.push('/register')"
                    >
                        Register
                    </base-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ServiceProviderItemUnAuth from "@/views/Components/ServiceProvider/ServiceProviderItemUnAuth.vue";
import { GET_ALL_SERVICE_PROVIDER_SERVICE_PROVIDERS } from "@/graphql/queries_unauth";
import { debounce } from "debounce";

export default {
    name: "DiscoverServiceProviders",
    components: {
        ServiceProviderItemUnAuth,
    },
    apollo: {
        all_service_providers: {
            client: "apolloClientUnAuth",
            query: GET_ALL_SERVICE_PROVIDER_SERVICE_PROVIDERS,
            result(data) {
                this.handle_all_service_providers(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.all_service_providers = data;
            },
        },
    },

    data() {
        return {
            apollo_data: {
                all_service_providers: [],
            },
            search: {
                search_term: "",
                is_loading: false,
            },
            service_providers: [],
            filtered_service_providers: [],
        };
    },
    methods: {
        handle_all_service_providers(data) {
            let service_providers = graph_utils.flatten_relay_response(
                data.data.allServiceProvidersServiceProvider
            );
            this.service_providers = [];
            service_providers.forEach((element) => {
                let service_provider = {
                    id: element.id,
                    name: element.name,
                    short_description: element.description,
                    picture_url: "",
                    location: element.location,
                    type: element.type__friendlyName,
                };
                if ("photo__file" in element) {
                    service_provider.picture_url = element.photo__file;
                }
                if ("rating__id" in element) {
                    service_provider.rating_count =
                        element.rating__submissions__totalCount;
                    service_provider.rating_stars = Number(
                        element.rating__stars
                    );
                }

                this.service_providers.push(service_provider);
                this.filtered_service_providers = this.service_providers;
            });
        },

        // Search Functionality

        on_keyup_search() {
            this.search.is_loading = true;
            this.debounced_on_keyup_search();
        },

        debounced_on_keyup_search: debounce(function () {
            this.$refs.search_form_submit.click();
        }, 1000),
        on_search_submit() {
            if (this.search.search_term === "") {
                this.filtered_service_providers = this.service_providers;
                setTimeout(() => {
                    this.search.is_loading = false;
                }, 500);
            } else {
                this.filtered_service_providers = this.filter_listings(
                    this.search.search_term,
                    this.service_providers
                );
                this.search.is_loading = false;
            }
        },

        filter_listings(search_term, in_array) {
            let out_array = in_array.filter((item) => {
                return (
                    item.name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.location
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.type
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.short_description
                        .toLowerCase()
                        .includes(search_term.toLowerCase())
                );
            });
            return out_array;
        },
    },
    created() {},
};
</script>
<style>
.profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
}

.blur-linear {
    -webkit-mask: -webkit-linear-gradient(black 55%, transparent 98%);
    /* -webkit-mask: -webkit-linear-gradient(black, transparent 30%, black); */
    /* -webkit-mask: linear-gradient(black, transparent 30%, black); */
    /* -webkit-filter: blur(3px); */
    /* mask: url("#mask-linear");
    filter: url("#filtre2"); */
    max-height: 950px;
}
@media only screen and (max-width: 600px) {
    .blur-linear {
        max-height: 1450px important!;
        -webkit-mask: -webkit-linear-gradient(black 95%, transparent 100%)
            important!;
    }
}
</style>
