import { User } from "@/ORM/Global/User.js";
import { Contract, ContractVersion } from "@/ORM/Contracts/Contract.js";

export class Execution {
  constructor(data = {}) {
    this.id = data.id || null;
    this.user = data.user ? new User(data.user) : new User();
    this.contract = data.contract
      ? new Contract(data.contract)
      : new Contract();
    this.contractVersion = data.contractVersion
      ? new ContractVersion(data.contractVersion)
      : new ContractVersion();
    this.location = data.location || null;
    this.gpsLong = data.gpsLong || null;
    this.gpsLat = data.gpsLat || null;
    this.ip = data.ip || null;
    this.platform = data.platform || null;
    this.userAgent = data.userAgent || null;
    this.createdDateTime = data.created || null;
  }

  static fromRelayData(inputObject) {
    const executions = [];

    if (
      inputObject &&
      inputObject.data &&
      inputObject.data.allContractExecution &&
      inputObject.data.allContractExecution.edges
    ) {
      inputObject.data.allContractExecution.edges.forEach((edge) => {
        if (edge.node) {
          executions.push(new Execution(edge.node));
        }
      });
    }

    return executions;
  }
}
