<template>
  <div>
    <div class="header pb-12 d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-4"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row>
          <b-col lg="10" md="10">
            <h1 class="display-2 text-white">
              {{ userTitle ? "Hello, " + userTitle + "!" : "Welcome!" }}
            </h1>
            <p class="text-white mt-0 mb-5">
              This is your profile page. Here you can update any information
              about you.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="4" class="order-xl-2">
          <!-- <user-card></user-card> -->
        </b-col>
        <b-col xl="8" class="order-xl-1">
          <edit-profile-form></edit-profile-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
// import UserCard from './UserProfile/UserCard.vue';

export default {
  name: "UserProfile",
  components: {
    EditProfileForm,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
      },
    };
  },
  methods: {
    loadUserData() {
      this.user = utils.deepcopy(this.$store.getters.getUser);
    },
  },
  computed: {
    userTitle() {
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      return this.user.first_name
        ? capitalizeFirstLetter(this.user.first_name)
        : null;
    },
  },
  created() {
    this.loadUserData();
    global_event_emitter.$on("profile_update", () => {
      setTimeout(() => {
        this.loadUserData();
      }, 500);
    });
  },
  beforeDestroy() {
    global_event_emitter.$off("profile_update");
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center 50%;
  min-height: 400px;
}
</style>
