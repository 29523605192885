<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="">
        <b-row>
          <b-col class="mt-4">
            <div
              @click="$router.back()"
              class="icon icon-shape rounded-circle shadow bg-default text-white"
            >
              <i class="fa fa-arrow-left"></i>
            </div>
          </b-col>
          <b-col lg="11" class="">
            <h1 class="display-1 text-orange pb-0 mb-0">Manage Investment</h1>
            <p class="text-default bold mt-0 mb-5 font-weight-bold">
              {{ listing_name }}
              <Transition>
                <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
              </Transition>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="investment_id !== null">
          <b-col>
            <!-- Add a card to wrap the contents -->
            <b-card no-body>
              <template v-if="financings.length">
                <!-- Tabs for financing -->
                <b-tabs @activate-tab="beforeActivate" class="ml-1 mt-2">
                  <b-tab
                    v-for="financing in financings"
                    :key="financing.id"
                    :title="financing.financingType__friendlyName"
                  >
                    <template #title>
                      {{ financing.financingType__friendlyName }}
                      <i
                        class="fas fa-trash ml-2 text-muted delete-icon"
                        @click.stop="
                          removeFinancingType(
                            financing.id,
                            financing.financingType__friendlyName
                          )
                        "
                      ></i>
                    </template>
                    <b-card-body>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <rental-income-finance
                            :listing_id="listing_id"
                          ></rental-income-finance>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <operating-costs
                            :investment_id="investment_id"
                            :financing_id="financing.id"
                            :financing_name="
                              financing.financingType__friendlyName
                            "
                          ></operating-costs>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <investment-financing
                            :investment_id="investment_id"
                            :financing_id="financing.id"
                          ></investment-financing>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <buying-costs
                            :investment_id="investment_id"
                            :financing_id="financing.id"
                            :financing_name="
                              financing.financingType__friendlyName
                            "
                          ></buying-costs>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span> + </span>
                    </template>
                  </b-tab>
                </b-tabs>
              </template>
              <!-- Show dropdown and create button if no financings exist -->
              <template v-else>
                <b-card class="mx-auto my-4 p-3">
                  <b-form-group
                    label="Select Financing Type"
                    label-class="font-weight-bold"
                  >
                    <b-form-select
                      v-model="selected_financing_type"
                      style="width: 100%"
                    >
                      <option
                        v-for="type in financing_types"
                        :key="type.id"
                        :value="type.id"
                      >
                        {{ type.friendly_name }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                  <b-button
                    variant="primary"
                    @click="addFinancing"
                    class="w-100 mt-2"
                  >
                    Add Financing
                  </b-button>
                </b-card>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- Add Financing Type Modal -->
    <b-modal id="add-financing-modal" @ok="addFinancing">
      <template v-slot:modal-title>Add Financing</template>
      <b-form-group label="Financing Type Name">
        <b-form-select v-model="selected_financing_type">
          <option
            v-for="type in financing_types"
            :key="type.id"
            :value="type.id"
          >
            {{ type.friendly_name }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="mt-3">
        <b-form-checkbox v-model="is_default_financing">
          Make Default Financing
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
    <b-modal id="confirm-delete-modal" @ok="confirmDeleteFinancingType">
      <template #modal-title>Delete Financing Type</template>
      <p>
        Are you sure you want to delete the financing type:
        <strong>{{ selectedFinancingName }}</strong
        >?
      </p>
    </b-modal>
    <b-container class="mt--6"> </b-container>
  </div>
</template>
<script>
// Queries
import { GET_PROPERTY_LISTING_INVESTMENTS } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_NAME } from "@/graphql/queries";
import { GET_ALL_INVESTMENT_FINANCING_TYPES } from "@/graphql/queries";

// Mutations
import { CREATE_INVESTMENT_FINANCING } from "@/graphql/mutations";
import { DELETE_INVESTMENT_FINANCING } from "@/graphql/mutations";

import OperatingCosts from "@/views/Components/Listing/Finances/Forms/OperatingCosts.vue";
import BuyingCosts from "@/views/Components/Listing/Finances/Forms/BuyingCosts.vue";
import InvestmentFinancing from "@/views/Components/Listing/Finances/Forms/InvestmentFinancing.vue";
import RentalIncomeFinance from "@/views/Components/Listing/Finances/Forms/RentalIncomeFinance.vue";

export default {
  name: "ListingInvestments",
  components: {
    OperatingCosts,
    BuyingCosts,
    InvestmentFinancing,
    RentalIncomeFinance,
  },
  apollo: {
    get_listing_investments: {
      query: GET_PROPERTY_LISTING_INVESTMENTS,
      result(data) {
        this.handle_get_listing_investments(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_listing_investments = data;
      },
      variables() {
        return {
          listing_id: this.listing_id,
        };
      },
      skip: true,
    },
    get_listing_name: {
      query: GET_PROPERTY_LISTING_NAME,
      result(data) {
        this.handle_get_listing_name(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_listing_name = data;
      },
      variables() {
        return {
          listing_id: this.listing_id,
        };
      },
      skip: true,
    },
    get_all_investment_financing_types: {
      query: GET_ALL_INVESTMENT_FINANCING_TYPES,
      result(data) {
        this.handle_get_all_investment_financing_types(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name);
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_investment_financing_types = data;
      },
    },
  },
  data() {
    return {
      apollo_data: {
        get_listing_investments: null,
      },
      listing_id: null,
      investment_id: null,
      listing_name: null,
      financings: [],
      selectedFinancingId: null,
      selectedFinancingName: "",
      financing_types: {},
      selected_financing_type: null,
      is_default_financing: false,
      skipRefetch: false,
    };
  },
  methods: {
    beforeActivate(newTabIndex, oldTabIndex, event) {
      if (newTabIndex === this.financings.length) {
        // Prevent the "+" tab from activating
        event.preventDefault();
        // Open your modal
        this.openAddFinancingModal();
      }
    },
    openAddFinancingModal() {
      // Reset the active tab index to the first financing tab if necessary
      this.$bvModal.show("add-financing-modal");
    },
    async addFinancing() {
      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_INVESTMENT_FINANCING,
          variables: {
            investment_id: this.investment_id,
            financing_type_id: this.selected_financing_type,
            default: this.is_default_financing,
          },
        });

        const newFinancing = response.data.investmentCreateFinancing.financing;

        // If set to default, clear previous default and add new financing at the start
        if (this.is_default_financing) {
          this.financings = this.financings.map((financing) => ({
            ...financing,
            isDefault: false,
          }));
          this.financings.unshift({
            id: newFinancing.id,
            financingType__friendlyName:
              newFinancing.financingType.friendlyName,
            financingType__id: newFinancing.financingType.id,
            isDefault: true,
          });
        } else {
          // Add new financing at the end if it’s not default
          this.financings.push({
            id: newFinancing.id,
            financingType__friendlyName:
              newFinancing.financingType.friendlyName,
            financingType__id: newFinancing.financingType.id,
            isDefault: false,
          });
        }

        // Prevent any immediate refetch that could affect ordering
        this.skipRefetch = true;

        // Reset fields and close modal
        this.selected_financing_type = null;
        this.is_default_financing = false;
        this.$bvModal.hide("add-financing-modal");
      } catch (error) {
        console.error("Failed to add financing:", error);
      }
    },
    // Apollo handlers
    handle_get_listing_investments(data) {
      if (this.skipRefetch) {
        this.skipRefetch = false; // Reset the flag
        return;
      }

      // Handle data
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      if (flattened.allInvestmentInvestment.length > 0) {
        this.investment_id = flattened.allInvestmentInvestment[0].id;
        let financings = flattened.allInvestmentInvestment[0].financingSet;

        // Identify the default financing type
        const defaultFinancing = financings.find((fin) => fin.isDefault);
        const defaultType = defaultFinancing
          ? defaultFinancing.financingType__friendlyName
          : null;

        // Sort financings by financing type and date
        financings.sort((a, b) => {
          if (b.isDefault) return 1;
          if (a.isDefault) return -1;

          // If the financing type matches the default, group them together
          if (a.financingType__friendlyName === defaultType) return -1;
          if (b.financingType__friendlyName === defaultType) return 1;

          // Group by financing type
          if (a.financingType__friendlyName > b.financingType__friendlyName)
            return 1;
          if (a.financingType__friendlyName < b.financingType__friendlyName)
            return -1;

          // Sort by date within the same financing type
          return new Date(a.date) - new Date(b.date);
        });

        // Add option #number for each consecutive financing of the same type
        let financingCount = {};
        this.financings = financings.map((financing) => {
          const type = financing.financingType__friendlyName;
          if (!financingCount[type]) {
            financingCount[type] = 1;
          } else {
            financingCount[type]++;
            financing.financingType__friendlyName = `${type} ${financingCount[type]}`;
          }
          return financing;
        });
      }
    },
    handle_get_listing_name(data) {
      this.listing_name = data.data.propertyListing.name;
    },

    // Apollo Manage
    enable_get_listing_investments() {
      this.$apollo.queries.get_listing_investments.setOptions({
        fetchPolicy: "network-only",
      });
      if (!this.$apollo.queries.get_listing_investments.skip) {
        this.$apollo.queries.get_listing_investments.refetch();
      } else {
        this.$apollo.queries.get_listing_investments.skip = false;
        this.$apollo.queries.get_listing_investments.start();
      }
    },
    enable_get_listing_name() {
      if (!this.$apollo.queries.get_listing_name.skip) {
        this.$apollo.queries.get_listing_name.refetch();
      } else {
        this.$apollo.queries.get_listing_name.skip = false;
        this.$apollo.queries.get_listing_name.start();
      }
    },
    handle_get_all_investment_financing_types(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      flattened.allInvestmentFinancingType.forEach((financing_type) => {
        this.$set(this.financing_types, financing_type.name, {
          id: financing_type.id,
          friendly_name: financing_type.friendlyName,
        });
      });
    },
    // Open the confirmation modal with the selected financing details
    removeFinancingType(financing_id, financing_name) {
      this.selectedFinancingId = financing_id;
      this.selectedFinancingName = financing_name;
      this.$bvModal.show("confirm-delete-modal");
    },

    // Execute the mutation to delete the financing type after confirmation
    async confirmDeleteFinancingType() {
      try {
        const response = await this.$apollo.mutate({
          mutation: DELETE_INVESTMENT_FINANCING,
          variables: {
            financing_id: this.selectedFinancingId,
          },
        });

        const deletedId = response.data.investmentDeleteFinancing.deletedId;

        // Filter out the deleted financing from the local state
        this.financings = this.financings.filter(
          (financing) => financing.id !== deletedId
        );

        // Optionally handle success messages or UI updates
      } catch (error) {
        console.error("Failed to delete financing:", error);
      }
    },

    // Global State
    get_listing_id() {
      this.listing_id = this.$route.query.lid;
    },
  },
  mounted() {
    this.get_listing_id();
  },
  watch: {
    listing_id() {
      if (this.listing_id !== null) {
        this.enable_get_listing_investments();
        this.enable_get_listing_name();
      }
    },
  },
};
</script>
<style>
.delete-icon {
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.delete-icon:hover {
  color: var(--primary) !important;
}
</style>
