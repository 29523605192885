export class Contract {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name = data.name || "";
  }
}

export class ContractVersion {
  constructor(data = {}) {
    this.id = data.id || null;
    this.versionNumber = data.versionNumber || "";
  }
}
