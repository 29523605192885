<template>
  <div class="dashboard-content-window">
    <!-- Header -->
    <div class="header bg-gradient-success pt-6 py-lg-6 pt-lg-7">
      <b-container>
        <div class="header-body text-center">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white display-1">Discover Opportunities</h1>
              <p class="text-lead text-white">
                Browse & discover your next investment property
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>

    <b-container class="mt-4">
      <b-row class="mb-4 text-center">
        <!-- Search -->
        <b-col class="text-center">
          <!-- mr-sm-3 -->
          <b-form
            class="navbar-search form-inline navbar-search-light"
            id="navbar-search-main"
            @submit.prevent="on_search_submit"
          >
            <b-form-group class="mb-0">
              <b-input-group class="input-group-alternative input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-search"></i
                  ></span>
                </div>
                <b-form-input
                  placeholder="Search"
                  type="text"
                  v-model="search.search_term"
                  @keyup="on_keyup_search"
                >
                </b-form-input>

                <input type="submit" ref="search_form_submit" hidden />
              </b-input-group>
            </b-form-group>

            <button
              type="button"
              class="close"
              data-action="search-close"
              data-target="#navbar-search-main"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </b-form>
        </b-col>
        <b-col>
          <Transition>
            <i
              v-show="search.is_loading || $apollo.is_loading"
              class="fas fa-spinner fa-spin ml--3 mt-3 text-white"
            ></i>
          </Transition>
        </b-col>
      </b-row>
      <b-row class="mt-2 blur-linear">
        <discover-listing-feature-card-un-auth
          v-for="listing in filtered_property_listings"
          :key="listing.id"
          :id="listing.id"
          :name="listing.name"
          :address="listing.address"
          :bedrooms="listing.bedrooms"
          :bathrooms="listing.bathrooms"
          :cashflow="listing.cashflow"
          :buying_price="listing.buying_price"
          :image_url="listing.image"
          :tags="[]"
          :status="listing.status"
          :type="listing.type"
        ></discover-listing-feature-card-un-auth>
      </b-row>
      <b-row class="mt--5" v-show="!$apollo.is_loading">
        <b-col class="text-center">
          <h3>Please register to see more listings.</h3>
        </b-col>
      </b-row>
      <b-row v-show="!$apollo.is_loading">
        <b-col class="text-center">
          <base-button pill type="warning" @click="$router.push('/register')">
            Register
          </base-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
// Modules
import { debounce } from "debounce";

import DiscoverListingFeatureCardUnAuth from "@/views/Components/Listing/DiscoverListingFeatureCardUnAuth.vue";
import { GET_ALL_PROPERTY_LISTINGS_UNAUTH } from "@/graphql/queries_unauth";

export default {
  name: "DiscoverListings",
  components: {
    DiscoverListingFeatureCardUnAuth,
  },
  apollo: {
    get_all_property_listings_published: {
      client: "apolloClientUnAuth",
      query: GET_ALL_PROPERTY_LISTINGS_UNAUTH,
      result(data) {
        this.handle_get_all_property_listings_published(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_property_listings_published = data;
      },
      variables() {
        return {
          listing_status_ids: this.listing_status_ids,
        };
      },
    },
  },

  data() {
    return {
      user: {},
      allPropertyListing: "",
      allPublishedPropertyListingStatus: [],
      property_listings: [],
      filtered_property_listings: [],
      listing_status_ids: [],
      apollo_data: {
        get_all_property_listings_published: "",
      },
      search: {
        search_term: "",
        is_loading: false,
      },
    };
  },
  methods: {
    loadUserData() {
      this.user = utils.deepcopy(this.$store.getters.getUser);
    },
    getPublishedPropertyListingStatusId() {
      let flattened = graph_utils.flatten_relay_response(
        this.allPropertyListingStatus
      );

      this.listing_status_ids = [];
      let supported_statuses = ["PUBLISHED", "SOLD", "UNDER_OFFER"];
      flattened.forEach((element) => {
        if (supported_statuses.includes(element.name)) {
          this.listing_status_ids.push(element.id);
        }
      });
    },
    getPublishedPropertyListings() {
      this.getPublishedPropertyListingStatusId();
      this.$apollo.queries.get_all_property_listings_published.setOptions({
        fetchPolicy: "network-only",
      });
      if (!this.$apollo.queries.get_all_property_listings_published.skip) {
        this.$apollo.queries.get_all_property_listings_published.refetch();
      } else {
        this.$apollo.queries.get_all_property_listings_published.skip = false;
        this.$apollo.queries.get_all_property_listings_published.start();
      }
    },
    handleAllPropertyListingStatuses() {
      this.getPublishedPropertyListings();
    },

    handle_get_all_property_listings_published(data) {
      let flattened = graph_utils.flatten_relay_response(
        data.data.allPropertyListing
      );
      this.property_listings = [];
      flattened.forEach((element) => {
        let listing = {
          id: element.id,
          name: element.name,
          address: { city: element.address__cityName, suburb: "" },
          buying_price: {
            symbol: element.buyingPrice__currency_symbol || "R",
            amount: element.buyingPrice__amount,
          },
          cashflow: {
            symbol: element.totalExpectedRentalIncome__currency_symbol || "R",
            amount: element.totalExpectedRentalIncome__amount,
          },
          bathrooms: 0,
          bedrooms: 0,
          tags: [],
          type: element.listingType__friendlyName,
          image: "",
          status: {
            name: element.status__name,
            friendly_name: element.status__friendlyName,
          },
        };

        if (element.artifacts.length > 0) {
          listing.image = element.artifacts[0].file;
        }

        if (element.subunits.length == 1 && element.subunits[0].isOnlySubunit) {
          if (element.subunits[0].bathroomCount) {
            listing.bathrooms = parseFloat(element.subunits[0].bathroomCount);
          }
          if (element.subunits[0].bedroomCount) {
            listing.bedrooms = parseFloat(element.subunits[0].bedroomCount);
          }
        }

        this.property_listings.push(listing);
      });
      this.filtered_property_listings = this.property_listings;
    },
    // Search Functionality

    on_keyup_search() {
      this.search.is_loading = true;
      this.debounced_on_keyup_search();
    },

    debounced_on_keyup_search: debounce(function () {
      this.$refs.search_form_submit.click();
    }, 1000),
    on_search_submit() {
      if (this.search.search_term === "") {
        this.filtered_property_listings = this.property_listings;
        setTimeout(() => {
          this.search.is_loading = false;
        }, 500);
      } else {
        this.filtered_property_listings = this.filter_listings(
          this.search.search_term,
          this.property_listings
        );
        this.search.is_loading = false;
      }
    },

    filter_listings(search_term, in_array) {
      let out_array = in_array.filter((item) => {
        return (
          item.name.toLowerCase().includes(search_term.toLowerCase()) ||
          item.address.city.toLowerCase().includes(search_term.toLowerCase())
        );
        // item.creator.username
        //     .toLowerCase()
        //     .includes(search_term.toLowerCase()) ||
        // item.creator.first_name
        //     .toLowerCase()
        //     .includes(search_term.toLowerCase()) ||
        // item.creator.last_name
        //     .toLowerCase()
        //     .includes(search_term.toLowerCase()) ||
        // item.status
        //     .toLowerCase()
        //     .includes(search_term.toLowerCase()) ||
        // item.approval_status.friendly_name
        //     .toLowerCase()
        //     .includes(search_term.toLowerCase())
      });
      return out_array;
    },
  },
  created() {
    this.loadUserData();
    global_event_emitter.$on("profile_update", () => {
      setTimeout(() => {
        this.loadUserData();
      }, 500);
    });
  },
  beforeDestroy() {
    global_event_emitter.$off("profile_update");
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}

.enter-active,
.leave-active {
  transition: opacity 0, 15s;
}
.enter,
.leave-to {
  opacity: 0;
}

.blur-linear {
  -webkit-mask: -webkit-linear-gradient(black 55%, transparent 98%);
  /* -webkit-mask: -webkit-linear-gradient(black, transparent 30%, black); */
  /* -webkit-mask: linear-gradient(black, transparent 30%, black); */
  /* -webkit-filter: blur(3px); */
  /* mask: url("#mask-linear");
    filter: url("#filtre2"); */
  max-height: 2450px;
}
@media only screen and (max-width: 600px) {
  .blur-linear {
    max-height: 3450px important!;
    -webkit-mask: -webkit-linear-gradient(black 95%, transparent 100%)
      important!;
  }
}

.blurred-text {
  -webkit-filter: blur(5px);
}
</style>
