<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            DEV Page
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            Some new components are brewing
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row>
                <b-col sm="12">
                    <div>
                        <!-- <b-card>
                            <b-card-body class="p-0">
                                <listing-photo-manager
                                    :listing_id="listing_id"
                                ></listing-photo-manager>
                            </b-card-body>
                        </b-card> -->
                        <!-- <listing-finances>
                            
                        </listing-finances> -->
                        <!--  <listing-renovation-component></listing-renovation-component>


                        <future-rental-plan-component>
                            
                        </future-rental-plan-component>
                        <monthly-expenses-component
                            :listing_id="listing_id"
                        ></monthly-expenses-component> -->
                        <!-- <investment-financing
                            investment_id="SW52ZXN0bWVudE5vZGU6MQ=="
                        >
                        </investment-financing>
                        <operating-costs></operating-costs>
                        <buying-costs></buying-costs> -->
                        <!-- <cru-operating-cost
                        expense_id="UmVjdXJyaW5nRXhwZW5zZU5vZGU6Mg=="
                        investment_id="SW52ZXN0bWVudE5vZGU6MQ=="
                        
                        ></cru-operating-cost> -->
                        <!-- <crud-listing-unit-modal v-model="show_modal">
                        </crud-listing-unit-modal> -->
                        <b-button @click="show_modal_fn"> Test Modal </b-button>

                        <!-- <rating-stars-comp
                            :max_rating="5"
                            v-model="test_vmodel"
                            :disabled="test_vmodel_disabled"
                            numeric_rating_after
                        ></rating-stars-comp> -->
                        <!-- <rating-view-widget-service-provider></rating-view-widget-service-provider> -->
                    </div>
                </b-col>
                <b-col sm="4">
                    <!-- <profile-rating-widget></profile-rating-widget> -->
                </b-col>
            </b-row>
            <!-- <crud-rating-modal
                v-model="show_modal"
            > </crud-rating-modal> -->

            <sign-contract-modal
                v-model="show_modal"
            > </sign-contract-modal>
        </b-container>
    </div>
</template>
<script>
// import MonthlyExpensesComponent from "@/views/Components/Listing/MonthlyExpensesComponent.vue";
// import FutureRentalPlanComponent from "@/views/Components/Listing/FutureRentalPlanComponent.vue";
// import ListingRenovationComponent from "@/views/Components/Listing/ListingRenovationComponent.vue";
// import ListingFinances from "@/views/Components/Listing/Finances/ListingFinances.vue";
// import ListingPhotoManager from "@/views/Components/ListingPhotoManager.vue";

// import OperatingCosts from "@/views/Components/Listing/Finances/Forms/OperatingCosts.vue";
// import BuyingCosts from "@/views/Components/Listing/Finances/Forms/BuyingCosts.vue";
// import InvestmentFinancing from "@/views/Components/Listing/Finances/Forms/InvestmentFinancing.vue";

// import CruOperatingCost from "@/views/Components/Listing/Finances/Forms/CRUOperatingCost.vue";

// import CrudListingUnitModal from "@/views/Components/Listing/Unit/CRUDListingUnitModal.vue";
// import RatingStarsComp from "@/views/Components/Rating/RatingStarsComp.vue";
// import RatingViewWidgetServiceProvider from "@/views/Components/Rating/RatingViewWidgetServiceProvider.vue";
// import ProfileRatingWidget from "@/views/Components/Rating/ProfileRatingWidget.vue";
// import UnitRentalPlan from "@/views/Components/Listing/Unit/UnitRentalPlan.vue";
// import CrudRatingModal from "../Components/Rating/CrudRatingModal.vue";
import SignContractModal from "../Components/Contracts/SignContractModal.vue";

export default {
    name: "ComponentDev",
    components: {
        // MonthlyExpensesComponent,
        // FutureRentalPlanComponent,
        // ListingRenovationComponent,
        // ListingFinances,
        // ListingPhotoManager,
        // OperatingCosts,
        // BuyingCosts,
        // InvestmentFinancing,
        // CruOperatingCost,
        // CrudListingUnitModal,
        // UnitRentalPlan,
        // RatingStarsComp,
        // RatingViewWidgetServiceProvider,
        // ProfileRatingWidget,
        // CrudRatingModal,
        SignContractModal, 
    },

    data() {
        return {
            listing_id: "TGlzdGluZ05vZGU6OTE=",
            show_modal: false,
            test_vmodel: 3.75,
            test_vmodel_disabled: true,
        };
    },
    methods: {
        show_modal_fn() {
            this.show_modal = false;

            setTimeout(() => {
                this.show_modal = true;
            }, 50);
        },
    },
};
</script>
<style>
.profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
}
</style>
