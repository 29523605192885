<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row>
          <b-col lg="12">
            <h1 class="display-1 text-orange pb-0 mb-0">My Agreements</h1>
            <p class="text-default bold mt-0 mb-5 font-weight-bold">
              Let's keep track of your commitments!
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="mt--6">
      <b-row v-if="contract_agreements.length == 0 && !$apollo.loading">
        <b-col class="text-center">
          <h3 class="text-default">
            You currently don't have any active agreements.
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <h1 class="text-center">
            <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </h1>
        </b-col>
        <b-col sm="12">
          <div v-for="agreement in contract_agreements" :key="agreement.id">
            <agreement-item
              :execution_id="agreement.id"
              :contract_id="agreement.contract.id"
              :contract_name="agreement.contract.name"
              :created_date_time="setDate(agreement)"
            ></agreement-item>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AgreementItem from "@/views/Components/Contracts/AgreementItem.vue";
import { GET_ALL_EXECUTIONS_FOR_ACCOUNT } from "@/graphql/queries";
import { Execution } from "@/ORM/Contracts/Execution.js";
export default {
  name: "Agreements",
  components: {
    AgreementItem,
  },
  apollo: {
    allContractExecution: {
      query: GET_ALL_EXECUTIONS_FOR_ACCOUNT,
      result(data) {
        this.contract_agreements = Execution.fromRelayData(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name);
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          account_id: store.getters.getAccountIdB64,
        };
      },
    },
  },

  data() {
    return {
      contract_agreements: [],
    };
  },
  methods: {
    setDate(agreement) {
      const date = agreement.createdDateTime;
      return date;
    },
  },
};
</script>

<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
