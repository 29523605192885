<template>
  <div>
    <b-card>
      <b-card-body class="p-0">
        <b-row>
          <!-- Title -->
          <b-col class="text-left align-middle my-auto">
            <h3>{{ expense_name }}</h3>
          </b-col>
          <!-- Cost amount -->
          <b-col class="text-sm-left text-md-right align-middle my-auto">
            <h1 class="text-orange m-0 text-nowrap">
              {{ expense_currency }}
              {{ formatted_currency(expense_amount) }}
            </h1>
          </b-col>
          <!-- Buttons -->
          <b-col
            class="col-12 col-sm-3 text-left text-sm-right align-middle my-auto pr-sm-4"
          >
            <base-button
              type="default"
              size="sm"
              :pill="true"
              @click="$emit('edit', expense_id)"
            >
              <i class="fas fa-wrench"></i>
            </base-button>
            <base-button
              type="danger"
              size="sm"
              :pill="true"
              @click="$emit('delete', expense_id, expense_name)"
            >
              <i class="fa fa-trash"></i>
            </base-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
//Custom Components

export default {
  name: "OperatingCostsItem",
  emits: ["edit", "delete"],
  components: {},
  props: {
    expense_id: {
      type: String,
      description: "ID of the expense",
      default: "",
    },
    expense_name: {
      type: String,
      description: "Name of the expense",
      default: "",
    },
    expense_amount: {
      type: Number,
      description: "Amount of the expense",
    },
    expense_currency: {
      type: String,
      description: "Currency of the expense",
      default: "",
    },
  },

  data() {
    return {};
  },
  methods: {
    formatted_currency(value) {
      return value.toLocaleString();
    },
  },
  watch: {},
};
</script>
<style></style>
