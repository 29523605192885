<template>
    <div>
        <h4>{{ title }}</h4>
        <h6 class="mb-0">
            <rating-stars-comp v-model="rating" disabled></rating-stars-comp>
        </h6>
        <h6 class="ml-1">
            {{ date }}
        </h6>
        <p class="text-muted">{{ comment }}</p>
    </div>
</template>

<script>
// Compoonents
import RatingStarsComp from "@/views/Components/Rating/RatingStarsComp.vue";

export default {
    name: "RatingViewItem",
    components: { RatingStarsComp },
    props: {
        title: {
            type: String,
            description: "Title of the Rating",
            default: null, //null
        },
        rating: {
            type: Number,
            description: "Star rating of the review",
            default: null, //null
        },
        services: {
            type: Array,
            description: "Title of the Rating",
            default() {
                return ["Test"];
            },
        },
        show_services: {
            type: Boolean,
            description: "Show services",
            default: true,
        },
        comment: {
            type: String,
            description: "Comment of the Rating",
            default: null,
        },
        date: {
            type: String,
            description: "Date when the review took place.",
            default: null, //null
        },
    },
};
</script>

<style></style>
