<template>
    <div class="star-rating">
        <small class="rating-display" v-if="numeric_rating_before && !numeric_rating_disabled">
            <strong>{{ dynamic_rating + 1 }} / {{ max_rating }}</strong>
        </small>
        <label
            class="star-rating__star"
            v-for="rating_item in ratings"
            :key="rating_item"
            :class="{
                'is-selected':
                    (dynamic_rating >= rating_item && dynamic_rating != null) ||
                    (rating_item + 1 > dynamic_rating + 1 &&
                        dynamic_rating + 1 > rating_item),
                'is-disabled': disabled,
            }"
            v-on:click="set(rating_item)"
            v-on:mouseover="star_over(rating_item)"
            v-on:mouseout="star_out"
        >
            <input
                class="star-rating star-rating__checkbox"
                type="radio"
                :value="rating_item"
                v-model="dynamic_rating"
                :disabled="disabled"
            />
            <i
                class="fas fa-star-half-alt"
                v-if="
                    rating_item + 1 > dynamic_rating + 1 &&
                    dynamic_rating + 1 > rating_item
                "
            ></i>
            <i
                class="fas fa-star"
                v-else-if="
                    dynamic_rating >= rating_item && dynamic_rating != null
                "
            ></i>

            <i class="far fa-star" v-else></i>
        </label>
        <small
            class="rating-display"
            v-if="numeric_rating_after && !numeric_rating_disabled"
            ><strong>{{ dynamic_rating + 1 }} / {{ max_rating }}</strong>
        </small>
    </div>
</template>

<script>
export default {
    name: "RatingStarsComp",
    props: {
        value: {
            type: Number,
            description: "Maximum rating score",
            default: null,
        },
        disabled: {
            type: Boolean,
            description: "If the vield is disabled",
            default: false,
        },
        required: {
            type: Boolean,
            description: "If a rating is required",
            default: false,
        },
        max_rating: {
            type: Number,
            description: "Maximum rating score",
            default: 5,
        },
        numeric_rating_before: {
            type: Boolean,
            description: "Shows a numeric rating before",
            default: false,
        },
        numeric_rating_after: {
            type: Boolean,
            description: "Shows a numeric rating after",
            default: false,
        },
        numeric_rating_disabled: {
            type: Boolean,
            description: "Disables numeric ratings.",
            default: false,
        },
    },
    data() {
        return {
            temp_value: null,
            dynamic_rating: this.value - 1,
            ratings: [1, 2, 3, 4, 5],
        };
    },
    methods: {
        star_over(index) {
            var self = this;

            if (!this.disabled) {
                this.temp_value = this.dynamic_rating;
                // this.$emit("input", this.temp_value + 1 );
                return (this.dynamic_rating = index);
            }
        },

        star_out() {
            var self = this;

            if (!this.disabled) {
                return (this.dynamic_rating = this.temp_value);
            }
        },

        set(dynamic_rating) {
            var self = this;

            if (!this.disabled) {
                this.temp_value = dynamic_rating;
                this.$emit("input", this.temp_value + 1);
                return (this.dynamic_rating = dynamic_rating);
            }
        },
    },
    mounted() {
        this.ratings = Array.from(Array(this.max_rating).keys());
    },
    created() {
        this.ratings = Array.from(Array(this.max_rating).keys());
    },
    watch: {
        value(new_value) {
            this.dynamic_rating = new_value - 1;
        },
    },
};
</script>

<style>
.rating-display {
    font-size: 120% !important;
}

.is-disabled:hover {
    cursor: default;
}

.star-rating__checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.star-rating__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #ababab;
    transition: color 0.2s ease-out;
}

.star-rating__star.is-selected {
    color: #f28e01;
}
.star-rating__star.is-disabled:hover {
    cursor: default;
}

.star-rating__star.is-disabled:hover {
    cursor: default;
}

.star-rating__star:hover {
    cursor: pointer;
}
</style>

<!-- 

%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.star-rating {

  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #ABABAB;
    transition: color .2s ease-out;

    &:hover {
      cursor: pointer;
    }
    
    &.is-selected {
      color: #FFD700;
    }
    
    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}



 -->
