<template>
    <div>
        <b-card>
            <!-- Card body -->
            <b-card-body class="p-0">
                <!-- <b-card-title title-tag="h5" class="h1 mb-0"
                    >Get started with Argon</b-card-title
                > -->
                <b-row>
                    <!-- Title -->
                    <b-col sm="3">
                        <b-row>
                            <b-col>
                                <h2 class="font-weight-bold mb-0">
                                    {{ name }}
                                </h2>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <small class="text-muted">
                                    <i class="fa-solid fa-house"></i>
                                    {{ type }}
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <small class="text-muted">
                                    <i class="ni ni-pin-3"></i>
                                    {{ address }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="1" lg="2">
                        <b-row class="pt-2">
                            <b-col class="px-0">
                                <badge
                                    :rounded="true"
                                    size="md"
                                    class="mx-1"
                                    :type="status_type"
                                    >{{ status }}</badge
                                >
                                
                                <badge
                                    :key="tag.text"
                                    v-for="tag in tags"
                                    :rounded="true"
                                    class="mx-1"
                                    size="md"
                                    :type="tag.type"
                                    >{{ tag.text }}</badge
                                >
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Bed and bath -->
                    <b-col sm="3" lg="2">
                        <b-row class="mt-3">
                            <b-col v-if="bedrooms" lg="4" class="p-0">
                                <p class="text-muted font-weight-bold">
                                    <i class="fa fa-bed"></i>
                                    {{ bedrooms }}
                                </p>
                            </b-col>
                            <b-col v-if="bathrooms" lg="4" class="p-0">
                                <p class="text-muted font-weight-bold">
                                    <i class="fa fa-bath"></i>
                                    {{ bathrooms }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Cash Stats -->
                    <b-col sm="4">
                        <b-row>
                            <b-col>
                                <div v-if="cashflow.amount != 0">
                                    <b-row
                                        ><b-col>
                                            <p class="mb--2 font-weight-bold">
                                                Rental Income
                                            </p>
                                        </b-col></b-row
                                    ><b-row
                                        ><b-col class="ml-2">
                                            <h1
                                                class="text-nowrap text-orange font-weight-bold m-0"
                                            >
                                                {{ cashflow.symbol }}
                                                {{
                                                    formatted_currency(
                                                        cashflow.amount
                                                    )
                                                }}
                                                <!-- R 4,500 -->
                                            </h1>
                                        </b-col></b-row
                                    >
                                </div>
                            </b-col>
                            <b-col>
                                <div v-if="buying_price.amount != 0">
                                    <b-row
                                        ><b-col>
                                            <p class="mb--2 font-weight-bold">
                                                Negotiated Price
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-row
                                        ><b-col class="ml-2">
                                            <h1
                                                class="text-nowrap text-black-50 font-weight-bold m-0"
                                            >
                                                {{ buying_price.symbol }}
                                                {{
                                                    formatted_currency(
                                                        buying_price.amount
                                                    )
                                                }}
                                                <!-- R 350,500 -->
                                            </h1>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right">
                                
                                
                                <router-link
                                    :to="{
                                        path: `/listing/view?id=${id}`,
                                    }"
                                    v-if="id"
                                >
                                    <base-button
                                        type="default"
                                        size="sm"
                                        :pill="true"
                                        class="mt-4 mx-1"
                                    >
                                        View Listing
                                    </base-button>
                                </router-link>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!-- <b-card-text class="mt-1"
                    >Argon is a great free UI package based on Bootstrap 4 that
                    includes the most important components and
                    features.</b-card-text
                >
                <a href="#!" class="btn btn-link px-0">View article</a> -->
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Badge from "@/components/Badge.vue";
export default {
    name: "PortfolioInvestmentItem",
    components: {
        Badge,
    },
    props: {
        id: {
            type: String,
            description: "",
            default: "",
        },

        name: {
            type: String,
            description: "Name",
            default: "",
        },
        status: {
            type: String,
            description: "Status",
            default: "",
        },
        
        type: {
            type: String,
            description: "Type",
            default: "",
        },
        address: {
            type: String,
            description: "Display Adress",
            default: "",
        },
        bedrooms: {
            type: Number,
            description: "Number of bedrooms",
            default: 0,
        },
        bathrooms: {
            type: Number,
            description: "Number of bathrooms",
            default: 0,
        },
        cashflow: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
            // default: { symbol: "R", amount: 0 },
        },
        buying_price: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
        },
        tags: {
            type: Array,
            description: "List of tags",
            default(rawProps) {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        formatted_currency(value) {
            return value.toLocaleString();
        },
    },
    computed: {
        status_type() {
            if (this.status == "Sold") {
                return "info";
            }  else {
                return "warning";
            }
        },
       
    },
};
</script>
<style></style>
